import { ResponsiveLine, PointTooltipProps } from '@nivo/line';
import { BasicTooltip } from '@nivo/tooltip';
import { ChartType } from '@types';
import type { FC } from 'react';

interface Props {
  lineData: ChartType[];
}

export const LineGraph: FC<Props> = ({ lineData }) => {
  const Tooltip: FC<PointTooltipProps> = ({ point }) => {
    return <BasicTooltip id={point.serieId} value={point.data.y} color={point.serieColor} />;
  };

  return (
    <ResponsiveLine
      data={lineData}
      margin={{ top: 60, right: 30, bottom: 50, left: 45 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        reverse: false,
      }}
      pointSize={10}
      useMesh={true}
      tooltip={Tooltip}
      pointColor={{ from: 'color', modifiers: [] }}
      theme={{
        tooltip: {
          container: {
            backgroundColor: 'transparent',
            position: 'relative',
            padding: '0.5em',
            zIndex: '99999',
            height: '52px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            backgroundPosition: 'top center',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            color: '#fff',
            textShadow:
              '-1px -1px 0 #1c5165, 1px 1px 0 #1c5165, -1px 1px 0 #1c5165, 1px -1px 0 #1c5165, 3px 3px 6px rgba(0, 0, 0, 0.25)',
            backgroundImage:
              'url(https://ic-common.pmang.cloud/static/bdt_book/img/button/button_type2__off.png)',
          },
        },
        background: '#21313e',
        axis: {
          ticks: {
            line: {
              stroke: '#555555',
            },
            text: {
              fill: '#ffffff',
            },
          },
          legend: {
            text: {
              fill: '#aaaaaa',
            },
          },
        },
        grid: {
          line: {
            stroke: '#21313e',
          },
        },
      }}
    />
  );
};
