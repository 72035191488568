import { useGaHook } from 'hooks/useGaHook';
import type { FC } from 'react';

interface Props {
  starImgUrl: string;
  canStarLevelUp: () => boolean;
  canStarLevelDown: () => boolean;
  changeStarLevel: ({ isDown }: { isDown: boolean }) => void;
  charName: string;
}
export const UpgradeButton: FC<Props> = ({
  canStarLevelUp,
  canStarLevelDown,
  changeStarLevel,
  starImgUrl,
  charName,
}) => {
  const { gaCharacterGradeAndLevelChange } = useGaHook();
  return (
    <div className="col-12 content__info__spinner--grade button_group">
      <button
        className={`button col-3 type4 minus  ${canStarLevelDown() && 'off'}`}
        type="button"
        onClick={() => {
          gaCharacterGradeAndLevelChange(charName, true);
          changeStarLevel({ isDown: true });
        }}
      ></button>
      <span className="col-6 content__info__spinner--grade--display">
        <img src={starImgUrl} alt="" />
      </span>
      <button
        className={`button col-3 type4 plus ${canStarLevelUp() && 'off'}`}
        type="button"
        onClick={() => {
          gaCharacterGradeAndLevelChange(charName, false);
          changeStarLevel({ isDown: false });
        }}
      ></button>
    </div>
  );
};
