import type { CharacterDetailType } from '@types';
import { useCharacterInfoHook } from 'hooks/useCharacterInfoHook';
import { useI18Hook } from 'hooks/useI18Hook';
import type { FC } from 'react';
import { CharaterSkillList } from './CharaterSkillList';
import { CharaterStats } from './CharaterStats';
import { MainSkill } from './MainSkill';
import { MisticSkillList } from './MisticSkillList';

interface Props {
  totalData: CharacterDetailType;
  charName: string;
}
export const CharacterInfo: FC<Props> = ({ totalData, charName }) => {
  const { tt } = useI18Hook();

  const {
    stat,
    changeLevel,
    changeMinMaxLevel,
    changeStarLevel,
    canStarLevelUp,
    canStarLevelDown,
    canLevelDown,
    skillList,
    mainSkill,
    isMistic,
    misticSkillList,
    skillRange,
  } = useCharacterInfoHook(totalData);

  return (
    <div className="col">
      <div className="content__info">
        <CharaterStats
          stat={stat}
          changeLevel={changeLevel}
          changeMinMaxLevel={changeMinMaxLevel}
          changeStarLevel={changeStarLevel}
          canStarLevelUp={canStarLevelUp}
          canStarLevelDown={canStarLevelDown}
          canLevelDown={canLevelDown}
          isMistic={isMistic}
          charName={charName}
        />

        <div className="content__info--skill">
          <h3 className="substring">{tt('Skill')}</h3>
          <div className="box">
            <MainSkill mainSkill={mainSkill} />
            {isMistic && misticSkillList ? (
              <MisticSkillList misticSkillList={misticSkillList} skillRange={skillRange} />
            ) : (
              <CharaterSkillList stat={stat} skillList={skillList} data={totalData} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
