import { Dispatch, FC, SetStateAction } from 'react';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
export const SkillSwitchButton: FC<Props> = ({ setIsOpen, isOpen }) => {
  return (
    <div className={`content__skillList--switch`} style={{ marginTop: 10 }}>
      <button
        style={{ transform: `rotate(${isOpen ? '180deg' : '0deg'} )` }}
        type="button"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      ></button>
    </div>
  );
};
