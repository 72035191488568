import App from 'App';
import axios from 'axios';
import mitt from 'mitt';
import { createRoot } from 'react-dom/client';
import './assets/styles/style.scss';
import './lang/i18n';

// SEE: https://github.com/developit/mitt#typescript
type AppEvents = {
  /** 점검 상태 */
  underMaintenance: boolean;
};

// PUB/SUB
const eventBus = mitt<AppEvents>();

export type AppEventBus = typeof eventBus;

axios.interceptors.response.use(undefined, function (error: unknown) {
  // 서버에서 점검일 경우 404 응답이 옴
  // if (error instanceof AxiosError) {
  //   if (error.response?.status === 404) {
  //     eventBus.emit('underMaintenance', true);
  //   }
  // }

  // 하지만 기존 코드가 API 에러일 경우 무조건 점검 페이지를 표시
  // 인프라 관련 에러로 5XX 에러가 날 수도 있음...
  eventBus.emit('underMaintenance', true);

  return Promise.reject(error);
});

const container = document.getElementById('root');
if (!container) {
  throw new Error('Container is missing!!!');
}

const root = createRoot(container);
root.render(<App eventBus={eventBus} />);
