import { FC } from 'react';

interface Props {
  statisticsDetails: {
    positionRanking: string;
    positionX: string;
    positionY: string;
    rate: string;
  };
}

export const PositionItem: FC<Props> = ({ statisticsDetails }) => {
  const { positionRanking, positionX, positionY, rate } = statisticsDetails;
  return (
    <div
      data-position-rank={positionRanking}
      data-position-x={positionX}
      data-position-y={positionY}
      data-percent-value={(parseFloat(rate) * 100).toFixed(2)}
    >
      <strong>{positionRanking}</strong>
      <span>{(parseFloat(rate) * 100).toFixed(2)} %</span>
    </div>
  );
};
