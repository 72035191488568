import type { MainSkillType } from '@types';
import type { FC } from 'react';
import { getMainSkillRangeName, getSkillText } from 'utils';

interface Props {
  mainSkill: MainSkillType;
}
export const MainSkill: FC<Props> = ({ mainSkill }) => {
  return (
    <div className="content__skillMain">
      <span className="content__skillMain--type1">{mainSkill.skillCoolTime}R</span>
      <span className="content__skillMain--type2">
        {getMainSkillRangeName(mainSkill.skillRange)}
      </span>
      <span className="content__skillMain--name">{getSkillText(mainSkill.name)}</span>
      <p className="content__skillMain--desc">{getSkillText(mainSkill.desc)}</p>
    </div>
  );
};
