import type { FC } from 'react';
import { Tab } from './Tab';

interface Props {
  currentTab: 'BattleBookEncyclopedia' | 'MercenaryEncyclopedia' | 'SkillEncyclopedia';
}

const tabList = [
  { label: 'BattleBookEncyclopedia', to: '/' },
  { label: 'MercenaryEncyclopedia', to: '/mercsindex?tabCode=0' },
  { label: 'SkillEncyclopedia', to: '/skillIndex' },
];

export const PageTab: FC<Props> = ({ currentTab }) => {
  return (
    <div className="button_group row select_bookType">
      {tabList.map((item, index) => (
        <Tab key={'tab' + index} currentTab={currentTab} label={item.label} to={item.to} />
      ))}
    </div>
  );
};
