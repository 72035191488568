import { MisticSkillType, UpgradeSkillType } from '@types';
import { useI18Hook } from 'hooks/useI18Hook';
import { Dispatch, FC, SetStateAction } from 'react';
import { resourceUrl } from 'utils/constants';

interface Props {
  row: number;
  skill: MisticSkillType;
  setUpgradeArray: Dispatch<SetStateAction<UpgradeSkillType[]>>;
  setActiveSkillIndex: Dispatch<SetStateAction<{ row: number; colum: number }>>;
  activeSkillIndex: { row: number; colum: number };
}

export const MainSkillRow: FC<Props> = ({
  row,
  skill,
  setUpgradeArray,
  activeSkillIndex,
  setActiveSkillIndex,
}) => {
  const { tt } = useI18Hook();
  return (
    <li className="mistic-skill-row">
      <div className="skill-text title type2">
        <span>{tt('Skill') + row}</span>
      </div>
      <div className={`skill-icon-list-row`}>
        {skill.skillInfo.map((item, colum) => {
          return (
            <div
              className={`skill-icon ${
                activeSkillIndex.row === row && activeSkillIndex.colum === colum && 'skillup'
              }`}
              key={'key' + colum}
              onClick={() => {
                setActiveSkillIndex({ row, colum });
                setUpgradeArray(skill.skillDetail[colum]);
              }}
            >
              <button type="button">
                <img src={`${resourceUrl}/skill/${item.imgUrl}.png`} alt="" />
              </button>
            </div>
          );
        })}
      </div>
    </li>
  );
};
