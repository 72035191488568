import { menuType } from '@types';
import { useI18Hook } from 'hooks/useI18Hook';
import type { FC } from 'react';
import { SubMenuItem } from './SubMenuItem';

interface Props {
  menu: menuType;
}

export const MenuItem: FC<Props> = ({ menu }) => {
  const { tt } = useI18Hook();
  if (tt(menu.title))
    return (
      <li className={menu.isActive ? 'isActive' : ''}>
        {menu.href ? (
          <a href={menu.href} rel="noreferrer noopener">
            <span>{tt(menu.title)}</span>
          </a>
        ) : (
          <a rel="noreferrer noopener">
            <span>{tt(menu.title)}</span>
          </a>
        )}

        <div className="submenu">
          <ul>
            {menu.subMenu.map((submenu, submenuIndex) => (
              <SubMenuItem key={'submenu' + submenuIndex} submenu={submenu} />
            ))}
          </ul>
        </div>
      </li>
    );

  return <></>;
};
