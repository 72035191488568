import { useI18Hook } from 'hooks/useI18Hook';
import { resourceUrl } from 'utils/constants';

export const Header = () => {
  const { getCurrentLang } = useI18Hook();
  const getMainBgUrl = (isMobile?: boolean) => {
    if (getCurrentLang() === 'en') {
      if (isMobile) return `${resourceUrl}/img/common/${getCurrentLang()}/head-m2.png`;
      return `${resourceUrl}/img/common/${getCurrentLang()}/head2.png`;
    }
    return `${resourceUrl}/img/common/${getCurrentLang()}/head${isMobile ? '-m' : ''}.png`;
  };

  return (
    <header id="header">
      <a href="/">
        <picture>
          <source media="(max-width: 768px)" srcSet={getMainBgUrl(true)} />
          <source media="(min-width: 768px)" srcSet={getMainBgUrl()} />
          <img src="" alt="" />
        </picture>
      </a>
    </header>
  );
};
