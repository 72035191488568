export const Storage = () => {
  const lang = localStorage.getItem('BROWNDUST_SELECT_LANGUAGES') || 'ko';
  const getLanguage = () => {
    return lang;
  };
  const setLanguage = (language: string) => {
    localStorage.setItem('BROWNDUST_SELECT_LANGUAGES', language);
  };

  return {
    getLanguage,
    setLanguage,
  };
};
