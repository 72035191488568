import { Footer } from 'components/layout/Footer';
import { GlobalHeader } from 'components/layout/GlobalHeader';
import { Header } from 'components/layout/Header';
import { useI18Hook } from 'hooks/useI18Hook';
import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { AppRouter } from 'routes';
import { Maintenance } from './components/Maintenance';
import { AppEventBus } from './main';

interface Props {
  eventBus: AppEventBus;
}

const App = (props: Props) => {
  // 점검 상태
  const [underMaintenance, setMaintenance] = useState(false);

  const { getCurrentLang, changeLan } = useI18Hook();

  // html lang에 따라 css가 달라지는 부분이 있어 추가
  useEffect(() => {
    const html = document.querySelector('html');
    html?.setAttribute('lang', getCurrentLang());
  }, [getCurrentLang()]);

  useEffect(() => {
    if (
      window.location.href === 'https://book.bravenine.com/#/en' ||
      window.location.href === 'https://book.bravenine.com'
    ) {
      changeLan('en');
    } else {
      const lang = location.href.split('#/')[1];
      if (lang) changeLan(lang);
    }
  }, []);

  useEffect(() => {
    props.eventBus.on('underMaintenance', (eventPayload) => {
      setMaintenance(eventPayload);
    });

    return () => {
      props.eventBus.off('underMaintenance');
    };
  }, []);

  return (
    <RecoilRoot>
      <div id="wrapper">
        <BrowserRouter>
          <GlobalHeader />
          <Header />
          {underMaintenance ? <Maintenance /> : <AppRouter />}
          <Footer />
        </BrowserRouter>
      </div>
    </RecoilRoot>
  );
};

export default App;
