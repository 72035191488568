import type { MisticSkillType, TipInfo, UpgradeSkillType } from '@types';
import { SkillSwitchButton } from 'components/layout/SkillSwitchButton';
import type { FC } from 'react';
import { useState } from 'react';
import { MisticFirstSkill } from './MisticFirstSkill';
import { MainSkillRow } from './MisticSkillRow';
import { MisticUpgradeSkillRow } from './MisticUpgradeSkillRow';
import { SkillTooltipPopup } from './SkillTooltipPopup';

interface Props {
  misticSkillList: MisticSkillType[];
  skillRange: string;
}

/* 
1번 스킬
고정
2번 스킬 리스트
3번 스킬 리스트
4번 스킬 리스트
*/
export const MisticSkillList: FC<Props> = ({ misticSkillList, skillRange }) => {
  const [activeSkillIndex, setActiveSkillIndex] = useState({ row: -1, colum: -1 });
  const [visible, setVisible] = useState(false);
  const [popupData, setPopupData] = useState<TipInfo[]>([]);
  const [upgrdaeArray, setUpgradeArray] = useState<UpgradeSkillType[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="content__skillList isOpen mistic">
        <ul>
          {misticSkillList.map((skill, index) => {
            if (index === 0) {
              return (
                <MisticFirstSkill
                  skillRange={skillRange}
                  activeSkillIndex={activeSkillIndex}
                  setActiveSkillIndex={setActiveSkillIndex}
                  skill={skill}
                  key={'misticSkill' + index}
                  setUpgradeArray={setUpgradeArray}
                />
              );
            }

            if (isOpen)
              return (
                <MainSkillRow
                  setActiveSkillIndex={setActiveSkillIndex}
                  skill={skill}
                  key={'misticSkill' + index}
                  row={index + 1}
                  setUpgradeArray={setUpgradeArray}
                  activeSkillIndex={activeSkillIndex}
                />
              );
          })}
        </ul>

        {isOpen && (
          <MisticUpgradeSkillRow
            upgrdaeArray={upgrdaeArray}
            setPopupData={setPopupData}
            setVisible={setVisible}
          />
        )}
      </div>

      <SkillTooltipPopup popupData={popupData} setVisible={setVisible} visible={visible} />

      <SkillSwitchButton isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
