import type { CharacterDetailType } from '@types';
import { useI18Hook } from 'hooks/useI18Hook';
import type { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getCharName, getImgUrl, getTrendList } from 'utils';

interface Props {
  characterData: CharacterDetailType | undefined;
}
export const TrendItem: FC<Props> = ({ characterData }) => {
  const { tt } = useI18Hook();
  const [searchParams] = useSearchParams();
  const trendData = getTrendList(searchParams);
  let characterInfoObj;
  if (characterData) characterInfoObj = JSON.parse(characterData.CharBasicList[0].value);
  const charName = characterInfoObj && getCharName(characterInfoObj);
  const imgUrl = characterInfoObj && getImgUrl(characterInfoObj);

  return (
    <div className="row">
      <div className="col-12">
        <div className="mercsTitle">
          {trendData.rank !== 'NaN' && (
            <div className="mercsTitle_no">
              <span>{trendData.rank}</span>
            </div>
          )}

          {trendData.isUpDown !== 'undefined' && (
            <div className="mercsTitle_fluctuation">
              <span data-rank={trendData.isUpDownStatus}>{trendData.isUpDown}</span>
            </div>
          )}

          <div className="mercsTitle_name">
            {imgUrl && <img src={`${imgUrl}`} alt={charName} />}

            <span>{charName}</span>
          </div>
          <div className="mercsTitle_trend">
            <div className="trend-container">
              <span className="time">{tt('LastWeek')}</span>
              {Number(trendData.current) > 0.0 && (
                <div className="bar">
                  <div className="inner-bar first" style={{ width: trendData.current + '%' }}></div>
                </div>
              )}

              <span className="percent">{trendData.current}</span>
            </div>

            <div className="trend-container">
              <span className="time">{tt('2WeeksAgo')}</span>

              {Number(trendData.before) > 0.0 && (
                <div className="bar">
                  <div className="inner-bar second" style={{ width: trendData.before + '%' }}></div>
                </div>
              )}
              <span className="percent">{trendData.before}</span>
            </div>

            <div className="trend-container">
              <span className="time">{tt('3WeeksAgo')}</span>

              {Number(trendData.previous) > 0.0 && (
                <div className="bar">
                  <div
                    className="inner-bar third"
                    style={{ width: trendData.previous + '%' }}
                  ></div>
                </div>
              )}
              <span className="percent">{trendData.previous}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
