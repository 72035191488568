import type { SkillItemType } from '@types';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useCallback } from 'react';
import { getColorObject, getSkillText } from 'utils';
import { resourceUrl } from 'utils/constants';

interface Props {
  setSearchText: Dispatch<SetStateAction<string>>;
  setItemActive: Dispatch<SetStateAction<boolean>>;
  setActiveTab: Dispatch<SetStateAction<number>>;
  item: SkillItemType;
  index: number;
}

export const AutoCompleteItem: FC<Props> = ({
  setSearchText,
  setItemActive,
  setActiveTab,
  item,
  index,
}) => {
  // 자동 완성에서 클릭 후 위치 이동 함수
  const clickAutoCompleteInputItem = useCallback((result: SkillItemType) => {
    setActiveTab(result._tabNameCodeLink);
    requestAnimationFrame(() => {
      const cardDom = document.querySelectorAll('.card .col-10.skill-name');
      cardDom.forEach((dom) => {
        if (
          dom.textContent === getColorObject(getSkillText(result._buffCodeObject._toolTipObj1)).text
        ) {
          window.scrollTo(0, dom.getBoundingClientRect().y - 200);
        }
      });
    });
  }, []);

  return (
    <div
      key={'autoComplete' + index}
      className="search-item"
      onClick={() => {
        setSearchText(getColorObject(getSkillText(item._buffCodeObject._toolTipObj1)).text);
        setItemActive(false);
        clickAutoCompleteInputItem(item);
      }}
    >
      <span>
        <img
          src={`${resourceUrl}/skill/${item._buffCodeObject._buffBigIcon.split('*')[1]}.png`}
          alt=""
        />
      </span>
      <span
        style={{
          color: getColorObject(getSkillText(item._buffCodeObject._toolTipObj1)).color,
        }}
      >
        {getColorObject(getSkillText(item._buffCodeObject._toolTipObj1)).text}
      </span>
    </div>
  );
};
