import type { StatType } from '@types';
import { useGaHook } from 'hooks/useGaHook';
import { useI18Hook } from 'hooks/useI18Hook';
import type { FC } from 'react';

interface Props {
  stat: StatType;
  changeLevel: ({ isDown }: { isDown: boolean }) => void;
  changeMinMaxLevel: ({ isMin }: { isMin: boolean }) => void;
  canLevelDown: () => boolean;
  charName: string;
}
export const LevelButton: FC<Props> = ({
  stat,
  changeLevel,
  changeMinMaxLevel,
  canLevelDown,
  charName,
}) => {
  const { tt } = useI18Hook();
  const { gaCharacterGradeAndLevelChange } = useGaHook();
  return (
    <div className="col-12 content__info__spinner--level button_group">
      <button
        className={`button col-1 type4 min ${canLevelDown() && 'off'}`}
        type="button"
        onClick={() => {
          gaCharacterGradeAndLevelChange(charName, true);
          changeMinMaxLevel({ isMin: true });
        }}
      >
        min
      </button>
      <button
        className={`button col-2 type4 minus ${canLevelDown() && 'off'}`}
        type="button"
        onClick={() => {
          gaCharacterGradeAndLevelChange(charName, true);
          changeLevel({ isDown: true });
        }}
      ></button>
      <span className="col-6 content__info__spinner--level--display">
        <em>
          {tt('Level')} {stat && stat.currentLevel}
        </em>
        /{stat && stat.maxLevel}
      </span>
      <button
        className={`button col-2 type4 plus ${stat.currentLevel === stat.maxLevel && 'off'}`}
        type="button"
        onClick={() => {
          gaCharacterGradeAndLevelChange(charName, false);
          changeLevel({ isDown: false });
        }}
      ></button>
      <button
        className={`button col-1  type4 max ${stat.currentLevel === stat.maxLevel && 'off'}`}
        type="button"
        onClick={() => {
          gaCharacterGradeAndLevelChange(charName, false);
          changeMinMaxLevel({ isMin: false });
        }}
      >
        max
      </button>
    </div>
  );
};
