import type { PositionCodeType } from '@types';
import { GatheringData } from 'components/layout/GatheringData';
import { useI18Hook } from 'hooks/useI18Hook';
import type { FC } from 'react';
import { PositionItem } from './PositionItem';

interface Props {
  positionList: PositionCodeType[];
}
export const BestPosition: FC<Props> = ({ positionList }) => {
  const { tt } = useI18Hook();

  return (
    <div className="col">
      <div className="content__info">
        <h3 className="substring ">{tt('UserDataTitle2')}</h3>
        <div className="box" id="box_bestPosition">
          {positionList.length > 0 ? (
            <div>
              {positionList.map((i, ii) => {
                return (
                  <PositionItem
                    key={ii + i.statisticsDetails.positionRanking}
                    statisticsDetails={i.statisticsDetails}
                  />
                );
              })}
            </div>
          ) : (
            <GatheringData />
          )}
        </div>
      </div>
    </div>
  );
};
