import type { FC } from 'react';

interface Props {
  index: number;
  rate: string;
  type?: string;
}

export const VerticalBarGrap: FC<Props> = ({ index, rate, type }) => {
  return (
    <div className="colum-bar" key={index + 'sortSkill'}>
      <div className="bar-container">
        <div className={`bar skill ${type}`} style={{ height: `${Number(rate) * 100}%` }}></div>
      </div>
      <div className="bar-label">
        {type !== 'attack-order' ? (index % 3 === 0 ? index : '') : index + 1}
      </div>
    </div>
  );
};
