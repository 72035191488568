import type { CharBalanceBasicListType, CharBasicType } from '@types';

export const statHelper = (
  characterInfo: CharBalanceBasicListType,
  characterStatList: CharBasicType[]
) => {
  // 스탯 및 스탯을 구하기 위한 helper 함수들
  const getBasic = (_type: number) => {
    const itemList = characterInfo['_itemList'];
    for (let i = 0; i < itemList.length; i++) {
      if (itemList[i]._type == _type) return itemList[i];
    }
    return null;
  };

  const getLevelRatio = (_level: number) => {
    const _specialLevelStep = parseInt(
      String(_level / parseFloat(String(characterInfo._specialGrowLevelFrequency)))
    );

    return parseInt(
      String(
        parseFloat(String(_level - 1)) +
          parseFloat(
            String(_specialLevelStep * parseFloat(String(characterInfo._specialGrowLevelMultiply)))
          )
      )
    );
  };

  const getDamage = (_damageValue: number, _type: number, _level: number): number => {
    const _basic = getBasic(_type);
    if (_basic == null) return 0.0;

    return (
      Number(parseFloat(String(_damageValue))) *
      Number(parseFloat(String(_basic._damageValue)) * getLevelRatio(_level))
    );
  };

  const getSupport = (_supportValue: number, _type: number, _level: number) => {
    const _basic = getBasic(_type);
    if (_basic == null) return 0.0;

    return (
      parseFloat(String(_supportValue)) *
      (parseFloat(String(_basic._supportValue)) * getLevelRatio(_level))
    );
  };

  const getHealth = (_health: number, _type: number, _level: number) => {
    const _basic = getBasic(_type);
    if (_basic == null) return 0.0;

    return (
      parseFloat(String(_health)) * (parseFloat(String(_basic._health)) * getLevelRatio(_level))
    );
  };

  const getDodgeRate = (_dodgeRate: number, _type: number, _level: number) => {
    const _basic = getBasic(_type);
    if (_basic == null) return 0.0;
    // 세트 용병 1 (용병 기본 정보와 동일)
    return (
      parseFloat(String(_dodgeRate)) *
      (parseFloat(String(_basic._dodgeRate)) * getLevelRatio(_level))
    );
  };

  const getCriticalRate = (_criticalRate: number, _type: number, _level: number) => {
    const _basic = getBasic(_type);
    if (_basic == null) return 0.0;

    return (
      parseFloat(String(_criticalRate)) *
      (parseFloat(String(_basic._criticalRate)) * getLevelRatio(_level))
    );
  };

  const getCriticalEffectRate = (_criticalEffectRate: number, _type: number, _level: number) => {
    const _basic = getBasic(_type);
    if (_basic == null) return 0.0;

    return (
      parseFloat(String(_criticalEffectRate)) *
      (parseFloat(String(_basic._criticalEffectRate)) * getLevelRatio(_level))
    );
  };

  const getDamageReduce = (_damageReduce: number, _type: number, _level: number) => {
    const _basic = getBasic(_type);
    if (_basic == null) return 0.0;

    return (
      parseFloat(String(_damageReduce)) *
      (parseFloat(String(_basic._damageReduce)) * getLevelRatio(_level))
    );
  };

  const floatIssueFirstProcess = (value: number) => {
    return Math.floor(value + parseFloat('0.09'));
  };

  const getBattleCharStat = (currentLevel: number, startLevelIndex: number) => {
    const charBasic = characterStatList[startLevelIndex];
    return {
      damageValue: Math.floor(
        parseInt(String(charBasic._damageValue)) +
          getDamage(
            parseFloat(String(charBasic._damageValue)),
            parseInt(String(String(charBasic._type))),
            currentLevel
          )
      ),
      supportValue: parseFloat(
        (
          (parseFloat(String(charBasic._supportValue)) +
            getSupport(
              parseFloat(String(charBasic._supportValue)),
              parseInt(String(String(charBasic._type))),
              currentLevel
            )) *
          100
        ).toFixed(2)
      ),
      maxHealth: parseInt(
        String(
          floatIssueFirstProcess(
            parseFloat(String(charBasic._health)) +
              getHealth(
                parseFloat(String(charBasic._health)),
                parseInt(String(charBasic._type)),
                currentLevel
              )
          )
        )
      ),
      currentHealth: parseInt(
        String(
          floatIssueFirstProcess(
            parseFloat(String(charBasic._health)) +
              getHealth(
                parseFloat(String(charBasic._health)),
                parseInt(String(charBasic._type)),
                currentLevel
              )
          )
        )
      ),
      dodgeRate: parseFloat(
        (
          (parseFloat(String(charBasic._dodgeRate)) +
            getDodgeRate(
              parseFloat(String(charBasic._dodgeRate)),
              parseInt(String(charBasic._type)),
              currentLevel
            )) *
          100
        ).toFixed(2)
      ),
      criticalRate: parseFloat(
        (
          (parseFloat(String(charBasic._criticalRate)) +
            getCriticalRate(
              parseFloat(String(charBasic._criticalRate)),
              parseInt(String(charBasic._type)),
              currentLevel
            )) *
          100
        ).toFixed(2)
      ),
      criticalEffectRate: parseFloat(
        (
          (parseFloat(String(charBasic._criticalEffectRate)) +
            getCriticalEffectRate(
              parseFloat(String(charBasic._criticalEffectRate)),
              parseInt(String(charBasic._type)),
              currentLevel
            )) *
          100
        ).toFixed(2)
      ),
      damageReduce: parseFloat(
        String(
          (parseFloat(String(charBasic._damageReduce)) +
            getDamageReduce(
              parseFloat(String(charBasic._damageReduce)),
              parseInt(String(charBasic._type)),
              currentLevel
            )) *
            100
        )
      ),
      protectedRate: 1.0,
    };
  };

  return { getBattleCharStat };
};
