import { useI18Hook } from 'hooks/useI18Hook';

export const NoTier = () => {
  const { tt } = useI18Hook();
  return (
    <div className="no-user">
      <p>{tt('NouserInTier')}</p>
    </div>
  );
};
