import type { SkillFilterCharacterTab } from '@types';
import type { Dispatch, FC, SetStateAction } from 'react';
import { getTabName } from 'utils';

interface Props {
  mobileSkillMenuVisible: boolean;
  setMobileskillMenuVisible: Dispatch<SetStateAction<boolean>>;
  skillTabList: SkillFilterCharacterTab[];
  activeTab: number;
}

export const MobileSkillSelectbox: FC<Props> = ({
  setMobileskillMenuVisible,
  mobileSkillMenuVisible,
  skillTabList,
  activeTab,
}) => {
  return (
    <button
      onClick={() => {
        setMobileskillMenuVisible(!mobileSkillMenuVisible);
      }}
      type="button"
      className={`skillIndex__button`}
    >
      {getTabName(skillTabList.find((item) => item._tabNameCode === activeTab))}
    </button>
  );
};
