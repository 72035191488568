import type { menuType, CharTypeNumber, StateType } from '@types';
import { SetterOrUpdater } from 'recoil';
const env = import.meta.env ? import.meta.env.VITE_APP_ENV : 'development';

export const ENV = {
  LOCAL: 'local',
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
};

export const resourceUrl =
  env === ENV.PRODUCTION
    ? 'https://ic-common.pmang.cloud/static/bdt_book'
    : 'https://ic-common-dq.pmang.cloud/static/bdt_book';
export const thumbnailUrl =
  env === ENV.PRODUCTION
    ? 'https://ic-common.pmang.cloud/static/bdt_book/thumbnail'
    : 'https://ic-common-dq.pmang.cloud/static/bdt_book/thumbnail';
export const runeUrl =
  env === ENV.PRODUCTION
    ? 'https://ic-common.pmang.cloud/static/bdt_book/rune'
    : 'https://ic-common-dq.pmang.cloud/static/bdt_book/rune';
export const selectLocalIconUrl =
  'https://ic-web-live.pmang.cloud/static/bdt_officialWeb/main/appHeader_object-selectLocale.png';

export const officialWebsiteUrl = (lang: string) =>
  lang === 'en' ? 'https://www.bravenine.com/' : 'https://www.browndust.app/';

export const koDqUrl = 'https://webservice-api.mbrown-dq.pmang.cloud';
export const koLiveUrl = 'https://webservice-api.mbrown.pmang.cloud';
export const globalDqUrl = 'https://webservice-api.mbrown-dq.pmang.cloud';
export const globalLiveUrl = 'https://webservice-api.mbrown.pmang.cloud';

export const gaId = 'UA-139305990-1';

export const ColorArray = [
  '#e0575b',
  '#af7ba1',
  '#4b9894',
  '#bab0ac',
  '#a1cce7',
  '#78b7b2',
  '#f9c0d2',
  '#79706e',
  '#f18c35',
  '#d27395',
  '#92725d',
  '#6ba2c9',
  '#f9bc2a',
  'rgb(186, 176, 172)',
  'rgb(225, 87, 89)',
  'rgb(78, 121, 167)',
  'rgb(73, 152, 148)',
  'rgb(140, 209, 125)',
  'rgb(176, 122, 161)',
  'rgb(157, 118, 96)',
  'rgb(89, 161, 79)',
];

export const GrowType = {
  AWAKEN_COMPLETE: 3, // 각성
};

export const filterTabText = {
  0: '신화',
  1: '초월',
  2: '전설',
  3: '공격',
  4: '방어',
  5: '마법',
  6: '지원',
};

interface StatisticsDataCodeType {
  closeCharacterCode: 14; // 주로 함께 사용하는 용병
  positionCode: 18; // 포지션 배치 순위
  runeCode: 15; // 룬조합 TOP 3
  pickCode: 11; // 등급별 픽률/승률
  attackOrderCode: 17; // 공격순서
  skillLevelCode: 16; // 스킬레벨
}

export const statisticsDataCode: StatisticsDataCodeType = {
  closeCharacterCode: 14,
  positionCode: 18,
  runeCode: 15,
  pickCode: 11,
  attackOrderCode: 17,
  skillLevelCode: 16,
};

export const SkillBuffInfo = {
  RANGE: 0, // 공격범위
  SKILL1: 1, // 1 스킬
  SKILL2: 2, // 2 스킬
  SKILL3: 3, // 3 스킬
  SKILL4: 4, // 각성.
};

export const skillUpgrade = {
  NONE: 0,
  UPGRADE: 1, // 업그레이드
  UPGRADE_EXTEND: 2, // 업그레이드 후
  NEW: 3, // 새로운 스킬
  LOCK: 4, // 락 걸린 스킬
  NEW_UPGRADE: 5, // 일괄 강화에서 사용.
};

export const skillArray = [
  { addClass: '', unlockLevel: '', img: '' },
  { addClass: '', unlockLevel: '', img: '' },
  { addClass: '', unlockLevel: '', img: '' },
  { addClass: '', unlockLevel: '', img: '' },
  { addClass: '', unlockLevel: '', img: '' },
];

// 랭킹 관련 정보 배열
export const tierCode = [
  { code: 0, label: 'total', desc: '전체 랭크' },
  { code: 1, label: 'TopRate', desc: '상위 랭크' },
  { code: 2, label: 'Crystal', desc: '크리스탈' },
  { code: 3, label: 'Diamond', desc: '다이아' },
  { code: 4, label: 'Platinum', desc: '플래티넘' },
  { code: 5, label: 'Gold', desc: '골드' },
  { code: 6, label: 'Silver', desc: '실버' },
  { code: 7, label: 'Bronze', desc: '브론즈' },
];

export const serverCode = [
  { code: 99, label: 'Origin', desc: '일반' },
  { code: 100, label: 'Terra', desc: 'PNE' },
];

export const modeCode = [
  { code: 1, label: 'Arena', desc: '결투장' },
  { code: 2, label: 'NoviceArena', desc: '신성결투장' },
  { code: 3, label: 'WorldBoss', desc: '월드보스' },
];

export const woldBossCode = [
  { code: 1, label: 'Ogdoad', desc: '' },
  { code: 2, label: 'Zaratan', desc: '' },
  { code: 3, label: 'Arcstar', desc: '' },
  { code: 4, label: 'Terion', desc: '' },
  { code: 5, label: 'Dagdarone', desc: '' },
  { code: 6, label: 'Gorgona', desc: '' },
];

export const timeCode = [
  { code: 11, label: 'Yesterday', desc: '어제' },
  { code: 12, label: 'LastWeek', desc: '지난 주' },
  { code: 13, label: 'LastMonth', desc: '지난 달' },
];

export const battleTypeCode = [
  { code: 0, label: 'All', desc: '전체' },
  { code: 1, label: 'Attack', desc: '공격' },
  { code: 2, label: 'Defense', desc: '방어' },
];

export const rateCode = [
  { code: 1, label: 'PickRate', desc: '픽률' },
  { code: 2, label: 'WinRate', desc: '승률' },
];

// 용병도감 탭
export const tabList: { label: string; code: CharTypeNumber }[] = [
  { label: 'Mythic', code: 0 },
  { label: 'Exalted', code: 1 },
  { label: 'Legend', code: 2 },
  { label: 'Warrior', code: 3 },
  { label: 'Defender', code: 4 },
  { label: 'Magician', code: 5 },
  { label: 'Supporter', code: 6 },
];

export const initStat = {
  currentLevel: -1,
  maxLevel: -1,
  battleStatus: {
    damageValue: 0,
    supportValue: 0,
    maxHealth: 0,
    currentHealth: 0,
    dodgeRate: 0,
    criticalRate: 0,
    criticalEffectRate: 0,
    damageReduce: 0,
    protectedRate: 0,
  },
  growType: -1,
  type: -1,
  star: -1,
  starImgUrl: '',
};

export const battleBookInitData = {
  BEFORE: [],
  PREVIOUS: [],
  RECENT: [],
};

export const characterDetailInitData = {
  CharBalanceList: '',
  CharBasicList: [],
  SkillBasicList: {},
  SkillTargetSearchType: {},
};

export const languageList = [
  { label: 'English', code: 'en' },
  { label: '한국어', code: 'ko' },
  { label: '日本語', code: 'ja' },
  { label: '繁體中文', code: 'zh' },
];

export const snsList = [
  { name: 'facebook', label: '페이스북', desc: '페이스북' },
  { name: 'twitter', label: '트위터', desc: '트위터' },
  { name: 'youtube', label: '유튜브', desc: '유튜브' },
  { name: 'cafe', label: '네이버 카페', desc: '네이버 카페' },
];

export const menuList = (lang: string): menuType[] => [
  {
    title: 'menuTitle1',
    subMenu: [
      {
        link: `${officialWebsiteUrl(lang)}${lang}/game/system`,
        title: 'initText1',
      },
      {
        link: `${officialWebsiteUrl(lang)}${lang}/game/world`,
        title: 'initText3',
      },
      {
        link: `${officialWebsiteUrl(lang)}${lang}/game/mercenaries`,
        title: 'initText4',
      },
    ],
  },
  {
    title: 'menuTitle3',
    subMenu: [
      {
        link: `${officialWebsiteUrl(lang)}${lang}/news`,
        title: 'menuTitle3',
      },
      {
        link: `${officialWebsiteUrl(lang)}${lang}/news/update`,
        title: 'menuTitle2',
      },
    ],
  },
  {
    title: 'menuTitle4',
    subMenu: [
      {
        link: `${officialWebsiteUrl(lang)}${lang}/media/video`,
        title: 'menuTitle8',
      },
      {
        link: `${officialWebsiteUrl(lang)}${lang}/media/wallpaper`,
        title: 'menuTitle9',
      },
      {
        link: `${officialWebsiteUrl(lang)}${lang}/media/fanart`,
        title: 'menuTitle10',
      },
      {
        link: `${officialWebsiteUrl(lang)}${lang}/media/fankit`,
        title: 'menuTitle11',
      },
    ],
  },
  {
    isActive: true,
    title: 'menuTitle6',
    href: '/',
    subMenu: [],
  },
  {
    title: 'menuTitle7',
    href: `https://story.browndust.app/${lang}`,
    subMenu: [],
  },
  {
    title: 'PNE',
    href: `https://crypto.bravenine.com/`,
    subMenu: [],
  },
];

export const iniImagePropsInfo = {
  mercenaries: { illust: '', inGame: '' },
  companions: { illust: '', inGame: '' },
};

export const filterList = {
  serverCode: serverCode.map((server) => {
    return {
      id: server.label,
      value: server.code,
      callback: (setState: SetterOrUpdater<StateType>, value: number, state: StateType) =>
        setState({
          ...state,
          serverCode: value,
        }),
    };
  }),
  contentCode: modeCode.map((mode) => {
    return {
      id: mode.label,
      value: mode.code,
      callback: (setState: SetterOrUpdater<StateType>, value: number, state: StateType) =>
        setState({
          ...state,
          isWoldBoss: mode.label === 'WorldBoss' ? 1 : 0,
          contentCode: value,
        }),
    };
  }),
  bossCode: woldBossCode.map((woldBoss) => {
    return {
      id: woldBoss.label,
      value: woldBoss.code,
      callback: (setState: SetterOrUpdater<StateType>, value: number, state: StateType) =>
        setState({
          ...state,
          bossCode: value,
        }),
    };
  }),
  tierCode: tierCode
    .filter((ii) => ii.code !== 0)
    .map((tier) => {
      return {
        id: tier.label,
        value: tier.code,
        callback: (setState: SetterOrUpdater<StateType>, value: number, state: StateType) =>
          setState({
            ...state,
            tierCode: value,
          }),
      };
    }),
  dataCode: timeCode.map((time) => {
    return {
      id: time.label,
      value: time.code,
      callback: (setState: SetterOrUpdater<StateType>, value: number, state: StateType) =>
        setState({
          ...state,
          dataCode: value,
        }),
    };
  }),
  attackCode: battleTypeCode.map((battleType) => {
    return {
      id: battleType.label,
      value: battleType.code,
      callback: (setState: SetterOrUpdater<StateType>, value: number, state: StateType) =>
        setState({
          ...state,
          attackCode: value,
        }),
    };
  }),
  rankCode: rateCode.map((rate) => {
    return {
      id: rate.label,
      value: rate.code,
      callback: (setState: SetterOrUpdater<StateType>, value: number, state: StateType) =>
        setState({
          ...state,
          rankCode: value,
        }),
    };
  }),
};
