import charTypeBg1 from 'assets/images/charTypeBg/charTypeBg1.png';
import charTypeBg2 from 'assets/images/charTypeBg/charTypeBg2.png';
import charTypeBg3 from 'assets/images/charTypeBg/charTypeBg3.png';
import charTypeBg4 from 'assets/images/charTypeBg/charTypeBg4.png';
import { skillUpgrade, GrowType, resourceUrl } from './constants';
import type {
  BufferInfo,
  CharacterDetailType,
  CharBasicType,
  CharBasicTypeListType,
  CustomBuffInfoType,
  CustomSkillType,
  DoubleNumberToStringObj,
  MainSkillNameType,
  ApiMisticSkillType,
  NumberToSkillObj,
  SkillType,
  TipInfo,
  MisticSkillType,
} from '@types';
import { isEmptyObject, makeCharacterBasicList } from 'utils';
import { Storage } from './storage';

// 용병 이미지 + 조력자 이미지 정보 추출
export const getImageInfo = (info: CharBasicType) => {
  // 용병 이미지 정보
  const mercenaries = {
    illust: '',
    inGame: '',
  };

  // 조력자 이미지 정보
  const companions = {
    illust: '',
    inGame: '',
  };

  if (info._npcPrefeb !== 'none') {
    mercenaries['illust'] = `${resourceUrl}/illust/${info._npcPrefeb}.png`;
  }

  if (info._uiCardImageName !== 'none') {
    mercenaries['inGame'] = `${resourceUrl}/character/${info._uiCardImageName.split('*')[1]}.png`;
  }

  if (!isEmptyObject(info._helperCharInfo)) {
    if (info._helperCharInfo._uiCardImageName !== 'none')
      companions['inGame'] = `${resourceUrl}/character/${
        info._helperCharInfo._uiCardImageName.split('*')[1]
      }.png`;

    if (info._helperCharInfo._npcPrefeb !== 'none')
      companions['illust'] = `${resourceUrl}/illust/${info._helperCharInfo._npcPrefeb}.png`;
  }

  return {
    mercenaries,
    companions,
  };
};

// 캐릭터 배경 이미지를 구함
export const getBgImg = (_type: number) => {
  switch (_type) {
    case 1:
      return charTypeBg1;
    case 2:
      return charTypeBg2;
    case 3:
      return charTypeBg3;
    case 4:
      return charTypeBg4;
    default:
      break;
  }
};

// 용병에 초기 성급 정보
export const getCharStar = (data: CharacterDetailType | undefined) => {
  if (data) return JSON.parse(data.CharBasicList[0].value)._star;
  return '0';
};

export const getMainSkill = (data: CharacterDetailType, skillInfo: SkillType) => {
  const mainSkillInfo = data?.SkillTargetSearchType || {};
  const searchType = skillInfo ? skillInfo._searchType : 0;
  const skillCoolTime = skillInfo ? skillInfo._coolTimeCount + 1 : 0;
  const targetSkill = mainSkillInfo[searchType] || "{}"; // undefined 일 경우 에러 처리
  const skillRange: MainSkillNameType = JSON.parse(targetSkill);

  return {
    skillCoolTime,
    skillRange,
    name: {
      _name: skillInfo._name,
      _name_JAP: skillInfo._name_JAP,
      _name_ENG: skillInfo._name_ENG,
      _name_TW: skillInfo._name_TW,
    },
    desc: {
      _name: data ? JSON.parse(data.CharBasicList[0].value)._name : '',
      _name_JAP: data ? JSON.parse(data.CharBasicList[0].value)._name_JAP : '',
      _name_ENG: data ? JSON.parse(data.CharBasicList[0].value)._name_ENG : '',
      _name_TW: data ? JSON.parse(data.CharBasicList[0].value)._name_TW : '',
    },
  };
};

// 각성 용병 스킬 코드 찾는 함수
const getCharAwakeSkillCode = (characterBasicList: CharBasicType[]) => {
  for (const characterBasic of characterBasicList) {
    if (characterBasic._growType == GrowType.AWAKEN_COMPLETE) {
      return characterBasic._skill;
    }
  }

  return null;
};

// 스킬 코드와 맞는 스킬 찾는 함수
const getMatchingCodeSkill = (
  skillBasicList: NumberToSkillObj,
  skillCode: number
): SkillType | null => {
  if (skillBasicList[skillCode]) return skillBasicList[skillCode];
  return null;
};

export const getSkillList = (
  characterBasicList: CharBasicType[],
  skillBasicList: NumberToSkillObj
) => {
  const { _skill, _growType } = characterBasicList[0];
  const skillList = [];
  const plusSkillLevel = _growType > 0 ? 15 : 0;

  for (let i = _skill; i <= _skill + plusSkillLevel; i++) {
    const skillInfo = getMatchingCodeSkill(skillBasicList, i);
    if (skillInfo) {
      skillList.push(skillInfo);
    }
  }
  return skillList;
};

// 각성 용병 스킬 리스트 설정
const getAwakeSkillList = (
  characterBasicList: CharBasicType[],
  skillBasicList: NumberToSkillObj
) => {
  const awakeSkillCode = getCharAwakeSkillCode(characterBasicList);
  const skillList = [];
  if (awakeSkillCode) {
    for (let i = awakeSkillCode; i <= awakeSkillCode + 15; i++) {
      const skillInfo = getMatchingCodeSkill(skillBasicList, i);
      if (skillInfo) {
        skillList.push(skillInfo);
      }
    }
  }
  return skillList;
};

export const getSkillImg = (
  buff: CustomBuffInfoType,
  allSkillData: CustomSkillType[],
  i: number
) => {
  if (buff.unLockLevel !== 0) {
    const skill = allSkillData[buff.unLockLevel].buffList[i].buffInfo;
    if (skill) return skill._buffBigIcon.split('*')[1];
  }
  if (buff.buffInfo) return buff.buffInfo._buffBigIcon.split('*')[1];
  return '';
};

const getSkillBuffInfoBasic = (skillList: SkillType[], awakeSkillList: SkillType[]) => {
  const skillBuffExtendBasicList = [];
  const hasAwakeSkill = awakeSkillList.length > 0;
  const { NONE, UPGRADE, UPGRADE_EXTEND, NEW, LOCK } = skillUpgrade;

  // 각성 여부에 따라 for문 도는 횟수가 다름
  const skillBuffExtendCheckLength = awakeSkillList.length > 0 ? 4 : 3;

  for (let i = 0; i < skillList.length; i++) {
    const buffList = [];
    const skillBasic = skillList[i];
    // 4개 스킬을 주입하여 줌
    // j === 0 일 때는 범위 스킬
    // j === 3 일 때는 각성 스킬
    for (let j = 0; j < 4; j++) {
      if (j === 0) {
        buffList.push({
          skillUpgrade: NONE,
          buffInfo: null,
          // [FIX]기존에는 _uiActionNum 이름으로 왔으나, 최근 API가 변경 되어 _uiActionIcon라는 이름으로 내려 온다.
          // _uiActionIcon에 GUI3*range_숫자 or GUI4*range_숫자로 값이 내려온다.
          // api주소/[]
          uiActionNum: skillBasic['_uiActionIcon'].split('range_')[1],
          unLockLevel: 0,
        });

        continue;
      }

      const index = ('_addBuffInfo' + j) as
        | '_addBuffInfo1'
        | '_addBuffInfo2'
        | '_addBuffInfo3'
        | '_addBuffInfo4';
      buffList.push({
        skillUpgrade: NONE,
        buffInfo: skillBasic[index],
        uiActionNum: '0',
        unLockLevel: 0,
      });

      // 각성 스킬 설정
      if (j === 3) {
        buffList.push({
          skillUpgrade: hasAwakeSkill ? LOCK : NONE,
          buffInfo: hasAwakeSkill ? awakeSkillList[i]['_addBuffInfo4'] : null,
          uiActionNum: '0',
          unLockLevel: 0,
        });
      }
    }

    skillBuffExtendBasicList.push({
      skillBasic,
      buffList,
    });
  }

  // 스킬 상태를 구함
  // 스킬 상태에 따라서 스킬 라벨 값이 달라짐
  for (let i = 1; i < skillBuffExtendBasicList.length; i++) {
    const bufferList = skillBuffExtendBasicList[i]['buffList'];
    const beforeBufferList = skillBuffExtendBasicList[i - 1]['buffList'];
    const uiActionNum = bufferList[0]['uiActionNum'];
    const beforeUiActionNum = beforeBufferList[0]['uiActionNum'];
    let newSkillIndex = 0;

    if (beforeUiActionNum !== uiActionNum) bufferList[0]['skillUpgrade'] = UPGRADE;
    for (let j = 1; j <= skillBuffExtendCheckLength; j++) {
      const bufferInfo = bufferList[j]['buffInfo'];
      const beforeBufferInfo = beforeBufferList[j]['buffInfo'];

      if (bufferInfo) {
        if (!beforeBufferInfo) {
          newSkillIndex = i;
          bufferList[j]['skillUpgrade'] = NEW;
        }

        if (beforeBufferInfo) {
          if (beforeBufferInfo['_buffBigIcon'] !== bufferInfo['_buffBigIcon']) {
            bufferList[j]['skillUpgrade'] = UPGRADE_EXTEND;
          }
          if (beforeBufferInfo['_code'] !== bufferInfo['_code']) {
            bufferList[j]['skillUpgrade'] = UPGRADE;
          }
        }
      }

      if (newSkillIndex > 0) {
        for (let k = newSkillIndex - 1; k >= 0; k--) {
          const newBufferInfo = skillBuffExtendBasicList[k]['buffList'][j];
          newBufferInfo['unLockLevel'] = newSkillIndex;
          newBufferInfo['skillUpgrade'] = LOCK;
        }
      }

      newSkillIndex = 0;
    }
  }

  return skillBuffExtendBasicList;
};

// 전체 스킬 정보를 셋팅
export const getSkillInfoList = (
  charBasicList: CharBasicTypeListType[] | undefined,
  skillBasicList: NumberToSkillObj
) => {
  const charList = makeCharacterBasicList(charBasicList);
  const skillList = getSkillList(charList, skillBasicList);
  const awakeSkillList = getAwakeSkillList(charList, skillBasicList);
  const totalSkillList = getSkillBuffInfoBasic(skillList, awakeSkillList);
  return totalSkillList;
};

// 튤팁 정보 배열을 리턴
export const getTooltipInfo = (buff: BufferInfo | null) => {
  const resultArray: TipInfo[] = [];
  if (buff) {
    buff._toolTipObj1 && resultArray.push(buff._toolTipObj1);
    buff._toolTipObj2 && resultArray.push(buff._toolTipObj2);
    buff._toolTipObj3 && resultArray.push(buff._toolTipObj3);
    buff._toolTipObj4 && resultArray.push(buff._toolTipObj4);
  }

  return resultArray;
};

// 튤팁에 디테일한 정보를 구하는 함수
export const getTooltipDetail = (buff: TipInfo | null) => {
  const { getLanguage } = Storage();
  const resultArray = {
    applyTimeText: '',
    icon: '',
    exception: '',
    help: '',
    name: '',
    skillTypeText: '',
    targetText: '',
    turnText: '',
  };

  if (buff) {
    resultArray.icon = buff._buffBigIcon.split('*')[1];
    switch (getLanguage()) {
      case 'ko':
        resultArray.applyTimeText = buff._applyTimeText;
        resultArray.exception = buff._exceptionText;
        resultArray.help = buff._help;
        resultArray.name = buff._name;
        resultArray.skillTypeText = buff._skillTypeText;
        resultArray.targetText = buff._targetText;
        resultArray.turnText = buff._turnText;
        break;
      case 'en':
        resultArray.applyTimeText = buff._applyTimeText_ENG;
        resultArray.exception = buff._exceptionText_ENG;
        resultArray.help = buff._help_ENG;
        resultArray.name = buff._name_ENG;
        resultArray.skillTypeText = buff._skillTypeText_ENG;
        resultArray.targetText = buff._targetText_ENG;
        resultArray.turnText = buff._turnText_ENG;
        break;
      case 'ja':
        resultArray.applyTimeText = buff._applyTimeText_JAP;
        resultArray.exception = buff._exceptionText_JAP;
        resultArray.help = buff._help_JAP;
        resultArray.name = buff._name_JAP;
        resultArray.skillTypeText = buff._skillTypeText_JAP;
        resultArray.targetText = buff._targetText_JAP;
        resultArray.turnText = buff._turnText_JAP;
        break;
      case 'zh':
        resultArray.applyTimeText = buff._applyTimeText_TW;
        resultArray.exception = buff._exceptionText_TW;
        resultArray.help = buff._help_TW;
        resultArray.name = buff._name_TW;
        resultArray.skillTypeText = buff._skillTypeText_TW;
        resultArray.targetText = buff._targetText_TW;
        resultArray.turnText = buff._turnText_TW;
        break;
    }
  }

  return resultArray;
};

/* 신화 스킬 정보 셋팅
신화 스킬에 경우 _addBuffInfo1 ~ _addBuffInfo4에 들어 있다.
신화가 아닌 경우 null 리턴
각 스킬은 6LEVEL 까지 있음
배열 순서 대로 능력이 증가 

_addSubBuffInfo1 = 
[
  스킬LEVEL1,
  스킬LEVEL2,
  스킬LEVEL3,
  스킬LEVEL4,
  스킬LEVEL5,
  스킬LEVEL6,
]

클릭 시 나오는 정보는 _toolTipObj안에 들어 있다.
최대 4개 까지 스킬이 있을 수 있다.
*/
export const getMisticSkill = (
  skillInfo: DoubleNumberToStringObj,
  CharUniqueCodeList = 2,
  isMistic: boolean
): MisticSkillType[] | null => {
  if (isMistic) {
    const innerCode = `${CharUniqueCodeList - 2}00`;
    const result: MisticSkillType[] = [];
    const misticSkill: ApiMisticSkillType = JSON.parse(
      skillInfo[CharUniqueCodeList][Number(innerCode)]
    );

    for (let i = 0; i < 4; i++) {
      const bufferName = ('_addBuffInfo' + (i + 1)) as
        | '_addBuffInfo1'
        | '_addBuffInfo2'
        | '_addBuffInfo3'
        | '_addBuffInfo4';

      const obj = {
        skillInfo: misticSkill[bufferName].map((i) => {
          return {
            imgUrl: i[0]._toolTipObj1?._buffBigIcon.split('*')[1] || '',
            _name: i[0]._toolTipObj1?._help || '',
            _name_ENG: i[0]._toolTipObj1?._help_ENG || '',
            _name_JAP: i[0]._toolTipObj1?._help_JAP || '',
            _name_TW: i[0]._toolTipObj1?._help_TW || '',
          };
        }),
        skillDetail: misticSkill[bufferName].map((item) => {
          return item.map((item2) => {
            const tooltipArray = [];
            if (item2._toolTipObj1) tooltipArray.push(item2._toolTipObj1);
            if (item2._toolTipObj2) tooltipArray.push(item2._toolTipObj2);
            if (item2._toolTipObj3) tooltipArray.push(item2._toolTipObj3);
            if (item2._toolTipObj4) tooltipArray.push(item2._toolTipObj4);
            return {
              icon: item2._buffBigIcon.split('*')[1],
              tooltipArray,
            };
          });
        }),
      };

      result[i] = obj;
    }
    return result;
  }

  return null;
};

export const getSkillRange = (charBasic: CharBasicType, skillInfo: SkillType) => {
  const range = skillInfo._uiActionIcon.split('range_')[1];
  const rangeImgUrl = `${resourceUrl}/skill/range/range_${range}${
    charBasic._type === 4 && charBasic._growType >= 0 ? '_y' : ''
  }.png`;

  return rangeImgUrl;
};
