import type {
  AttackOrderType,
  CharacterDetailType,
  CharBasicType,
  CloseCharDetailType,
  PositionCodeType,
  RuneType,
  SkillLevelType,
  WinOrPickRatioType,
} from '@types';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getThumbnailCodeList } from 'utils';
import { statisticsDataCode } from 'utils/constants';
import { useApi } from './useApiHook';

export const useStatistic = (id: string) => {
  const { getStatistics, getCloseCharacter, getDetailCharacters } = useApi();
  const [searchParams] = useSearchParams();
  // contentType이 1이면 결투장, 2라면 신성결투장(5성 미만 용병만 나옴)
  const [characterInfo, setCharacterInfo] = useState<CharBasicType>();
  const [contentType, setContentType] = useState(Number(searchParams.get('content')) || 1);
  const [characterData, setCharacterData] = useState<CharacterDetailType>();
  const [server, setServer] = useState(Number(searchParams.get('serverCode')) || 99);
  const [winOrPick, setWinOrPick] = useState<string>(searchParams.get('isWinRate') || 'PickRate');
  const [loading, setLoading] = useState(false);
  const [closeCharImg, setCloseCharImg] = useState<CloseCharDetailType[]>([]);
  const [positionList, setPositionList] = useState<PositionCodeType[]>([]);
  const [runeList, setRuneList] = useState<RuneType[]>();
  const [skillLevelList, setSkillLevelList] = useState<SkillLevelType[]>([]);
  const [attackOrderList, setAttackOrderList] = useState<AttackOrderType[]>([]);
  const [pickOrWinRatioList, setPickOrWinRatioList] = useState<{
    [key: number]: WinOrPickRatioType[];
  }>([[], [], []]);
  const { closeCharacterCode, positionCode, runeCode, attackOrderCode, skillLevelCode, pickCode } =
    statisticsDataCode;

  useEffect(() => {
    getData();
  }, [server, contentType]);

  const getData = useCallback(async () => {
    setLoading(true);
    const [data, characterList] = await Promise.all([
      getStatistics(id || '', server),
      getDetailCharacters(id || ''),
    ]);

    const characterInfoObj: CharBasicType = JSON.parse(characterList.CharBasicList[0].value);
    const thumbnailCodeList = getThumbnailCodeList(
      data.details[closeCharacterCode] ? data.details[closeCharacterCode][contentType] : []
    );
    const closeCharacterList = await getCloseCharacter(thumbnailCodeList);
    const runeList = data.details[runeCode];

    setCharacterData(characterList);
    setPositionList(data.details[positionCode] ? data.details[positionCode][contentType] : []);
    setCloseCharImg(closeCharacterList);
    if (runeList) setRuneList(runeList[contentType]);
    setSkillLevelList(
      data.details[skillLevelCode] ? data.details[skillLevelCode][contentType] : []
    );
    setAttackOrderList(
      data.details[attackOrderCode] ? data.details[attackOrderCode][contentType] : []
    );
    setPickOrWinRatioList(data.details[pickCode] ? data.details[pickCode] : [[], [], []]);
    setCharacterInfo(characterInfoObj);
    setLoading(false);
  }, [server, contentType]);

  return {
    closeCharImg,
    positionList,
    runeList,
    skillLevelList,
    attackOrderList,
    pickOrWinRatioList,
    loading,
    characterData,
    server,
    winOrPick,
    setServer,
    setWinOrPick,
    contentType,
    setContentType,
    characterInfo,
  };
};
