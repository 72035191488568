import { useCallback, useEffect } from 'react';

export const useDomChangeHook = (title: string) => {
  const updateTitle = useCallback(() => {
    const htmlTitle = document.querySelector('title');
    if (htmlTitle) htmlTitle.innerHTML = title;
  }, [title]);

  useEffect(() => {
    updateTitle();
  }, [title]);
};
