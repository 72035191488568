import type { CharacterType, SkillItemType, SkillFilterCharacterTab } from '@types';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { characterState, skillItemState, skillTabState } from 'store/state';
import { useApi } from './useApiHook';

export const useSkillTabHook = () => {
  const { getSkillList, getAllCharacters } = useApi();
  const [loading, setLoading] = useState(false);
  const [characterData, setCharacterData] = useRecoilState<CharacterType[]>(characterState);
  const [skillTabList, setSkillTabList] = useRecoilState<SkillFilterCharacterTab[]>(skillTabState);
  const [skillItemList, setSkillItemList] = useRecoilState<SkillItemType[]>(skillItemState);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const [data, { _itemList, _tabNameCode }] = await Promise.all([
        getAllCharacters(),
        getSkillList(),
      ]);
      setCharacterData(data);
      setSkillTabList(_tabNameCode);
      setSkillItemList(_itemList);
      setLoading(false);
    };
    if (characterData.length === 0 || skillTabList.length === 0) getData();
  }, []);

  return {
    characterData,
    skillTabList,
    skillItemList,
    loading,
  };
};
