import type { SkillIconType, TipInfo } from '@types';
import { useGaHook } from 'hooks/useGaHook';
import { useI18Hook } from 'hooks/useI18Hook';
import type { Dispatch, FC, SetStateAction } from 'react';
import { getColorObject } from 'utils';
import { resourceUrl } from 'utils/constants';

interface Props {
  item: SkillIconType;
  rowIndex: number;
  itemIndex: number;
  setPopupData: (item: TipInfo[]) => void;
  setVisible: Dispatch<SetStateAction<boolean>>;
}
export const SkillIcon: FC<Props> = ({ itemIndex, item, setPopupData, setVisible }) => {
  const { gaCharacterGetSkill } = useGaHook();
  const { tt } = useI18Hook();

  return (
    <>
      <div
        className={`content__skillList--div content__skillList--type${itemIndex} ${item.addClass}`}
      >
        <button
          data-unlock={item.unLockLevel + tt('Unlock')}
          type="button"
          onClick={() => {
            if (item.tipInfo.length > 0) {
              const skillName = getColorObject(item.tipInfo[0]._name).text;
              gaCharacterGetSkill(skillName);
              setVisible(true);
              setPopupData(item.tipInfo);
            }
          }}
        >
          <img src={`${resourceUrl}/skill/${item.img ? item.img : ''}.png`} alt="" />
        </button>
      </div>

      {itemIndex === 2 && <div className="content__skillList--div content__skillList--line"></div>}
    </>
  );
};
