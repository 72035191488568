import type { StatType } from '@types';
import { LevelButton } from 'components/battlebookInfo/LevelButton';
import { useI18Hook } from 'hooks/useI18Hook';
import type { FC } from 'react';
import { StatList } from './StatList';
import { UpgradeButton } from './UpgradeButton';

interface Props {
  stat: StatType;
  changeLevel: ({ isDown }: { isDown: boolean }) => void;
  changeMinMaxLevel: ({ isMin }: { isMin: boolean }) => void;
  changeStarLevel: ({ isDown }: { isDown: boolean }) => void;
  canStarLevelUp: () => boolean;
  canStarLevelDown: () => boolean;
  canLevelDown: () => boolean;
  isMistic: boolean;
  charName: string;
}

export const CharaterStats: FC<Props> = ({
  stat,
  changeLevel,
  changeMinMaxLevel,
  changeStarLevel,
  canStarLevelUp,
  canStarLevelDown,
  canLevelDown,
  charName,
}) => {
  const { tt } = useI18Hook();

  return (
    <div className="content__info--basic">
      <h3 className="substring ">{tt('Stats')}</h3>
      <div className="box">
        <div className="content__info__spinner row">
          <UpgradeButton
            canStarLevelUp={canStarLevelUp}
            canStarLevelDown={canStarLevelDown}
            changeStarLevel={changeStarLevel}
            starImgUrl={stat.starImgUrl}
            charName={charName}
          />
          <LevelButton
            stat={stat}
            changeLevel={changeLevel}
            changeMinMaxLevel={changeMinMaxLevel}
            canLevelDown={canLevelDown}
            charName={charName}
          />
        </div>
        <StatList stat={stat} />
      </div>
    </div>
  );
};
