import type { FilterCharacterTab, CharTypeNumber } from '@types';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useGaHook } from 'hooks/useGaHook';
import { useI18Hook } from 'hooks/useI18Hook';
import { useNavigate } from 'react-router-dom';

interface Props {
  setCurrentTab: Dispatch<SetStateAction<FilterCharacterTab>>;
  setSearchText: Dispatch<SetStateAction<string>>;
  activeClass: (activeTabName: CharTypeNumber) => string;
  label: string;
  filterType: CharTypeNumber;
}

export const FilterTab: FC<Props> = ({
  setCurrentTab,
  setSearchText,
  activeClass,
  label,
  filterType,
}) => {
  const navigation = useNavigate();
  const { gaCharacterFilterTabSelect } = useGaHook();
  const { tt } = useI18Hook();

  return (
    <button
      type="button"
      onClick={() => {
        setCurrentTab({ filterType, label });
        setSearchText('');
        gaCharacterFilterTabSelect(filterType);
        navigation(`/mercsindex?tabCode=${filterType}`, { replace: true });
      }}
      className={`button type2 ${activeClass(filterType)} `}
    >
      {/* 신화/초월의 경우 아이콘을 가지지 않음 */}
      {filterType > 1 && <i></i>}
      <span>{tt(label)}</span>
    </button>
  );
};
