import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { gaId } from 'utils/constants';

export const useRouteChangeHook = () => {
  const location = useLocation();
  const [init, setInit] = useState(false);

  useEffect(() => {
    ReactGA.initialize(gaId);
    setInit(true);
  }, []);

  useEffect(() => {
    if (init) {
      ReactGA.pageview(location.pathname);
    }
  }, [init, location]);
};
