import type {
  CharacterType,
  RankTypeData,
  SkillFilterCharacterTab,
  SkillItemType,
  StateType,
} from '@types';
import { atom } from 'recoil';
import { battleBookInitData } from 'utils/constants';

// 용병 전체 데이터
export const characterState = atom<CharacterType[]>({
  key: 'character',
  default: [],
});

// 랭크 데이터
export const rankDataState = atom<RankTypeData>({
  key: 'rankTypeData',
  default: battleBookInitData,
});

// 스킬 탭
export const skillTabState = atom<SkillFilterCharacterTab[]>({
  key: 'skillFilterCharacterTab',
  default: [],
});

// 스킬 아이템
export const skillItemState = atom<SkillItemType[]>({
  key: 'skillItemType',
  default: [],
});

// 메인 필터
export const mainFilterState = atom<StateType>({
  key: 'mainFilterState',
  default: {
    serverCode: 99,
    contentCode: 1,
    tierCode: 1,
    dataCode: 11,
    attackCode: 0,
    rankCode: 1,
    bossCode: 1,
    isWoldBoss: 0,
  },
});
