import { useI18Hook } from 'hooks/useI18Hook';
import type { Dispatch, FC, SetStateAction } from 'react';

interface Props {
  setSearchText: Dispatch<SetStateAction<string>>;
}

export const SearchInput: FC<Props> = ({ setSearchText }) => {
  const { tt } = useI18Hook();

  return (
    <div className="form-group search-area">
      <input
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
        type="search"
        id="search-Mercenary"
        autoComplete="off"
        placeholder={tt('SearchMercenary')}
      />
    </div>
  );
};
