import type { SkillItemType } from '@types';
import { useI18Hook } from 'hooks/useI18Hook';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { getSkillText } from 'utils';
import { AutoCompleteItem } from './AutoCompleteItem';

interface Props {
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  skillItemList: SkillItemType[];
  setActiveTab: Dispatch<SetStateAction<number>>;
}

export const SearchInput: FC<Props> = ({
  searchText,
  setSearchText,
  skillItemList,
  setActiveTab,
}) => {
  const [searchItemList, setSearchItemList] = useState<SkillItemType[]>([]);
  const [itemActive, setItemActive] = useState(false);
  const { tt } = useI18Hook();

  useEffect(() => {
    setSearchItemList(
      skillItemList.filter((i) => {
        // 소문자로 변환하여 비교
        const skill = getSkillText(i._buffCodeObject._toolTipObj1) || '';
        return skill.toLowerCase().includes(searchText.toLowerCase());
      })
    );
  }, [searchText]);

  return (
    <>
      <div className="form-group search-area">
        <input
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
          type="search"
          id="search-Skill"
          autoComplete="off"
          placeholder={tt('SearchSkill')}
          onFocus={() => setItemActive(true)}
        />

        {/* 자동완성 관련 */}
        <div
          className={`search-item-container ${
            itemActive && searchText.length > 0 && searchItemList.length > 0 && 'active'
          } `}
        >
          {searchItemList.map((item, index) => (
            <AutoCompleteItem
              key={'AutoCompleteItem' + index}
              item={item}
              index={index}
              setSearchText={setSearchText}
              setActiveTab={setActiveTab}
              setItemActive={setItemActive}
            />
          ))}
        </div>
      </div>
    </>
  );
};
