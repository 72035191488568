import { Main } from 'pages/main';
import { Routes, Route } from 'react-router-dom';
import { MercsIndex } from 'pages/mercsindex';
import { SkillIndex } from 'pages/skillIndex';
import { Detail } from 'pages/detail';
import { BattleBookInfo } from 'pages/battlebookInfo';
import { useRouteChangeHook } from 'hooks/useRouteChangeHook';
import { Notfound } from 'pages/notfound';
import { Redirect } from 'pages/redirect';

export const AppRouter = () => {
  useRouteChangeHook();
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/mercsindex" element={<MercsIndex />} />
      <Route path="/skillIndex" element={<SkillIndex />} />
      <Route path="/detail/:id" element={<Detail />} />
      <Route path="/battlebookInfo/:id" element={<BattleBookInfo />} />
      <Route path="/404" element={<Notfound />} />

      {/* legacy url redirect */}
      <Route path="/index.html" element={<Redirect />} />
    </Routes>
  );
};
