import type { FC } from 'react';

interface Props {
  rate: string;
  type?: string;
}
export const BarGrap: FC<Props> = ({ rate, type }) => {
  return (
    <div className="rate-bar-container type2">
      <div
        className={`rate-bar-inner ${type}`}
        style={{
          width: `${Number(rate)}%`,
        }}
      ></div>
      <div className="rate-bar-text">{Number(rate) > 0.1 ? rate + '%' : '0.01% ▼'}</div>
    </div>
  );
};
