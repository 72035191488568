import { Loading } from 'components/layout/Loading';
import { PageTab } from 'components/layout/PageTab';
import { MobileSkillSelectbox } from 'components/skillIndex/MobileSkillSelectbox';
import { SearchInput } from 'components/skillIndex/SearchInput';
import { SkillItem } from 'components/skillIndex/SkillItem';
import { SkillTab } from 'components/skillIndex/SkillTab';
import { useDomChangeHook } from 'hooks/useDomChangeHook';
import { useI18Hook } from 'hooks/useI18Hook';
import { useSkillTabHook } from 'hooks/useSkillTabHook';
import { useState } from 'react';

export const SkillIndex = () => {
  const [activeTab, setActiveTab] = useState(101);
  const [mobileSkillMenuVisible, setMobileskillMenuVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { skillTabList, skillItemList, characterData, loading } = useSkillTabHook();
  const { tt } = useI18Hook();
  useDomChangeHook(`${tt('SkillListTitle')}`);

  return (
    <section id="container">
      {loading && <Loading />}
      <PageTab currentTab={'SkillEncyclopedia'} />
      <SearchInput
        searchText={searchText}
        setSearchText={setSearchText}
        skillItemList={skillItemList}
        setActiveTab={setActiveTab}
      />

      <div className="content" id="skillIndex">
        <div className="row">
          <div className="button_group">
            <div className="col-3">
              {/* 모바일 시에만 나오는 모바일 셀렉터 */}
              <MobileSkillSelectbox
                mobileSkillMenuVisible={mobileSkillMenuVisible}
                setMobileskillMenuVisible={setMobileskillMenuVisible}
                skillTabList={skillTabList}
                activeTab={activeTab}
              />
              <ul className={`skillIndex__list ${mobileSkillMenuVisible && 'open'}`}>
                {/* 왼쪽 탭리스트, 모바일에선 selectbox에 아이템 */}
                {skillTabList.map((i) => {
                  return (
                    <SkillTab
                      setMobileskillMenuVisible={setMobileskillMenuVisible}
                      key={i._tabNameCode}
                      tabInfo={i}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  );
                })}
              </ul>
            </div>
            <div className="col-9">
              <div className="skillIndex__notice">{tt('ClickAlert')}</div>
              <div className="skillIndex__package">
                {/* 실제 스킬값, 탭과 맞는 코드만 filter 해서 보여줌 */}
                {skillItemList
                  .filter((i) => i._tabNameCodeLink === activeTab)
                  .map((i) => {
                    return (
                      <SkillItem
                        key={i._buffCode}
                        activeTab={activeTab}
                        skillInfo={i}
                        allCharactersData={characterData}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div id="scrollTop" onClick={() => window.scrollTo(0, 0)}></div>
      </div>
    </section>
  );
};
