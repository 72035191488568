import type { CharacterDetailType, SkillTotalType, StatType, TipInfo } from '@types';
import { SkillSwitchButton } from 'components/layout/SkillSwitchButton';
import type { FC } from 'react';
import { useState } from 'react';
import { SkillRow } from './SkillRow';
import { SkillTooltipPopup } from './SkillTooltipPopup';

interface Props {
  data: CharacterDetailType | undefined;
  stat: StatType;
  skillList: SkillTotalType[];
}

export const CharaterSkillList: FC<Props> = ({ stat, skillList }) => {
  const [visible, setVisible] = useState(false);
  const [popupData, setPopupData] = useState<TipInfo[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className={`content__skillList ${isOpen && 'isOpen'}`}>
        <ul>
          {skillList &&
            skillList.map((skill, index) => {
              return (
                <SkillRow
                  allSkillData={skillList}
                  key={`skill-${index}`}
                  skill={skill}
                  growType={stat.growType}
                  rowIndex={index}
                  type={stat.type}
                  setPopupData={setPopupData}
                  setVisible={setVisible}
                />
              );
            })}
        </ul>
      </div>

      <SkillSwitchButton isOpen={isOpen} setIsOpen={setIsOpen} />
      <SkillTooltipPopup popupData={popupData} visible={visible} setVisible={setVisible} />
    </>
  );
};
