import { useGaHook } from 'hooks/useGaHook';
import { useI18Hook } from 'hooks/useI18Hook';
import { useState } from 'react';
import { menuList, officialWebsiteUrl, selectLocalIconUrl, snsList } from 'utils/constants';
import { MenuItem } from './MenuItem';
import { SelectLanguagePopup } from './SelectLanguagePopup';
import { SnsItem } from './SnsItem';

export const GlobalHeader = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const { gaCommunitySelect } = useGaHook();
  const { getCurrentLang } = useI18Hook();

  const toggleMobileMenu = () => {
    setMobileMenuVisible(!mobileMenuVisible);
  };

  const openPopup = () => {
    gaCommunitySelect('국가 변경 선택');
    setVisible(true);
  };

  return (
    <div className="globalHeader">
      {visible && <SelectLanguagePopup setVisible={setVisible} />}
      <button type="button" className="mobileMenu" onClick={toggleMobileMenu}>
        <span></span>
      </button>
      <div className="mobileLogo">
        <a
          className={getCurrentLang() === 'en' ? 'logoGlobal' : ''}
          href={`${officialWebsiteUrl(getCurrentLang())}${getCurrentLang()}`}
        >
          Browndust
        </a>
      </div>
      <button type="button" onClick={openPopup} id="selectLanguage" className={getCurrentLang()}>
        <img src={selectLocalIconUrl} />
      </button>
      <div className={`header_inner ${mobileMenuVisible && 'isOpen'}`}>
        <div>
          <div className="logo">
            <a
              className={getCurrentLang() === 'en' ? 'logoGlobal' : ''}
              href={`${officialWebsiteUrl(getCurrentLang())}${getCurrentLang()}`}
            >
              Browndust
            </a>
          </div>
          <div className="menu">
            <ul>
              {menuList(getCurrentLang()).map((menu, menuIndex) => (
                <MenuItem key={menuIndex + 'menuIndex'} menu={menu} />
              ))}
            </ul>
            <div className="submenuBg"></div>
          </div>
          <div id="floatMenu">
            <ul>
              {snsList.map((sns, index) => (
                <SnsItem key={'sns' + index} snsItem={sns} />
              ))}
            </ul>
          </div>
        </div>
      </div>

      <button type="button" className="mobileMenuClose" onClick={toggleMobileMenu}></button>
    </div>
  );
};
