import type { FC } from 'react';
import type { CharacterType } from '@types';
import { getCharName, getImgUrl } from 'utils';
import { Link } from 'react-router-dom';
import { getBgImg } from 'utils/charaterInfo';
import { NewBadge } from './NewBadge';

interface Props {
  CharacterInfo: CharacterType;
}

export const CharacterItem: FC<Props> = ({ CharacterInfo }) => {

  return (
    <div
      className="col-2"
      data-grade={CharacterInfo._star}
      data-specific={CharacterInfo._type}
      data-grow={CharacterInfo._growType}
      // filterType === 0 => mistic임
      // 10/27일 업데이트로 5성 영웅이 모두 6성으로 내려 옴
      data-filterType={CharacterInfo._filterType}
    >
      <div className="content__charBlock">
        <img src={getBgImg(CharacterInfo._type)} />
        <Link to={`/detail/${CharacterInfo._uniqueCode}`}>
          {CharacterInfo._titleCode === 1 && <NewBadge />}
          <span className="content__charBlock--image">
            <img src={getImgUrl(CharacterInfo)} alt={getCharName(CharacterInfo)} />
          </span>
          <p className="content__charBlock--name ">{getCharName(CharacterInfo)}</p>
        </Link>
      </div>
    </div>
  );
};
