import type { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  icon: string;
  index: number;
  closeCharCode: string;
}
export const CloseCharIcon: FC<Props> = ({ icon, index, closeCharCode }) => {
  return (
    <li key={'closeCharacter' + index}>
      <Link to={`/detail/${closeCharCode}`}>
        <span className="content__charBlock--image">
          <img src={`${icon}`} alt={`${closeCharCode}`} />
        </span>
      </Link>
    </li>
  );
};
