import type { CharacterType, RankTypeData, Top10Type } from '@types';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { mainFilterState, rankDataState } from 'store/state';
import { useApi } from './useApiHook';

export const useRankHook = () => {
  const { getAllCharacters, getRankList, getWoldBossRankList, getTop10Character } = useApi();
  const [loading, setLoading] = useState(false);
  const [initRender, setInitRender] = useState(false);
  const [state, setState] = useRecoilState(mainFilterState);
  const [characterData, setCharacterData] = useState<CharacterType[]>([]);
  const [rankListData, setRankListData] = useRecoilState<RankTypeData>(rankDataState);
  const [top10List, setTop10List] = useState<Top10Type>({});

  const filterChange = useCallback(async () => {
    setLoading(true);
    const [data, top10data] = await Promise.all([
      state.isWoldBoss === 1 ? getWoldBossRankList(state) : getRankList(state),
      getTop10Character(state),
    ]);
    setTop10List(top10data);
    setRankListData(data);
    setLoading(false);
  }, [state]);

  useEffect(() => {
    const getData = async () => {
      const [data] = await Promise.all([getAllCharacters(), filterChange()]);
      setCharacterData(data);
      setInitRender(true);
    };
    if (characterData.length === 0) getData();
  }, []);

  useEffect(() => {
    if (initRender) filterChange();
  }, [state]);

  return { loading, setState, state, characterData, rankListData, top10List };
};
