import { useI18Hook } from '../hooks/useI18Hook';

export function Maintenance() {
  const { getCurrentLang, tt } = useI18Hook();
  const lang = getCurrentLang();
  const imgSrc = `https://ic-common.pmang.cloud/static/bdt_book/img/common/maintainance_${lang}.png`;
  const message = tt('Maintenance');

  return (
    <section id="container">
      <div className="content" id="maintenance">
        <div className="row">
          <div className="content__charBlock--none">
            <img className="content__charBlock--none--image" src={imgSrc} alt={message} />
            <p className="content__charBlock--none--title template">{message}</p>
          </div>
        </div>
      </div>
    </section>
  );
}
