import { fillterTitleType, StateType } from '@types';
import { useI18Hook } from 'hooks/useI18Hook';

import type { FC } from 'react';
import { SetterOrUpdater } from 'recoil';
import { convertLanName } from 'utils';
import { filterList } from 'utils/constants';
import { RadioButton } from './RadioButton';

interface Props {
  setState: SetterOrUpdater<StateType>;
  state: StateType;
  isWoldBoss: number;
}

export const BattleBookFilter: FC<Props> = ({ state, setState, isWoldBoss }) => {
  const { tt } = useI18Hook();

  return (
    <form id="battleBookSelectForm">
      <div
        className={`button_group row select_battleBookSort ${
          isWoldBoss === 1 ? 'bossSet' : 'arenaSet'
        }`}
      >
        {Object.keys(filterList).map((key) => {
          return (
            <div className={`sortBox sortBox_${key}`} key={key + 'filter'}>
              <div>
                <div className="sortBox_title">
                  <span>{tt(convertLanName(key) || '')}</span>
                </div>
                <div className="sortBox_desc">
                  <ul>
                    {filterList[key as fillterTitleType].map((item, index) => (
                      <RadioButton
                        fillterTitle={key as fillterTitleType}
                        state={state}
                        key={'changeServer' + index}
                        name={key}
                        item={item}
                        setState={setState}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </form>
  );
};
