import type { CharTypeNumber } from '@types';
import { useMemo } from 'react';
import ReactGA from 'react-ga';
import { filterTabText } from 'utils/constants';
import { Storage } from 'utils/storage';

export const useGaHook = () => {
  const EFFECTIVE_TYPE = useMemo(
    () => (!(navigator as any).connection ? 'unknown' : (navigator as any).connection?.effectiveType || ''),
    [(navigator as any).connection]
  );
  const { getLanguage } = Storage();

  const gaCharacterImageTypeSelect = (type: string) => {
    ReactGA.event({
      category: '용병 정보',
      action: `용병 이미지타입 클릭`,
      label: `현재 캐릭터 이미지 타입 ${type === 'illust' ? '일러스트' : '전투이미지'}`,
    });
  };

  const gaCharacterFilterTabSelect = (tab: CharTypeNumber) => {
    ReactGA.event({
      category: '용병 정보',
      action: '용병 타입 버튼 클릭',
      label: `용병 타입 : ${filterTabText[tab]}, 언어환경: ${getLanguage()}`,
    });
  };

  const gaCharacterGetSkill = (skillName: string) => {
    ReactGA.event({
      category: '용병 정보',
      action: '용병 스킬 조회',
      label: `캐릭터명 : 레피테아, 선택한 스킬 ${skillName}, 언어환경: ${getLanguage()}`,
    });
  };

  const gaCharacterGradeAndLevelChange = (characterName: string, isDown: boolean) => {
    ReactGA.event({
      category: '용병 정보',
      action: '용병 등급/레벨 조정',
      label: `캐릭터명 : ${characterName}, 등급 변화 : ${
        isDown ? 'DOWN' : 'UP'
      }, 언어환경: ${getLanguage()}`,
    });
  };

  const gaChangeBattleBookOption = () => {
    ReactGA.event({
      category: '배틀북 정보',
      action: '배틀북 옵션 변경',
      label: `접속환경 : ${EFFECTIVE_TYPE}, 언어환경 : ${getLanguage()}`,
    });
  };

  const gaCommunitySelect = (communityName: string) => {
    ReactGA.event({
      category: '커뮤니티 클릭 조회',
      action: communityName,
      label: `접속환경 : ${EFFECTIVE_TYPE}, 언어환경 : ${getLanguage()}`,
    });
  };

  // 스킬 정보 클릭 카테고리
  const gaSkillCategorySelect = (category: string) => {
    ReactGA.event({
      category: '스킬 정보',
      action: '스킬 카테고리 선택',
      label: `접속환경 : ${EFFECTIVE_TYPE}, 선택 스킬 : ${category}, 언어환경 : ${getLanguage()}`,
    });
  };

  // 스킬 정보 클릭 특정 스킬
  const gaSkillSelect = (skillName: string) => {
    ReactGA.event({
      category: '스킬 정보',
      action: '특정 스킬 선택',
      label: `접속환경 : ${EFFECTIVE_TYPE} 선택 스킬 : ${skillName}, 언어환경 : ${getLanguage()}`,
    });
  };

  const gaCharacterSelect = () => {
    ReactGA.event({
      category: '배틀북 정보',
      action: '특정 용병 선택',
      label: `접속환경 : ${EFFECTIVE_TYPE}, 언어환경 : ${getLanguage()}`,
    });
  };

  // TOP10 추세선 클릭
  const gaTop10Select = () => {
    ReactGA.event({
      category: '배틀북 정보',
      action: '배틀북 TOP10 추세선 클릭',
      label: `접속환경 : ${EFFECTIVE_TYPE}, 언어환경 : ${getLanguage()}`,
    });
  };

  const battleBookInfoChange = (type: string, server: string, charName: string) => {
    ReactGA.event({
      category: '배틀북 정보',
      action: '배틀북 정보 변경',
      label: `결투장 구분 : ${type}, 서버 구분 : ${server}, 캐릭터 이름 : ${charName}`,
    });
  };

  return {
    gaCharacterSelect,
    gaCommunitySelect,
    gaCharacterFilterTabSelect,
    gaCharacterGradeAndLevelChange,
    gaSkillSelect,
    gaSkillCategorySelect,
    gaTop10Select,
    gaChangeBattleBookOption,
    battleBookInfoChange,
    gaCharacterGetSkill,
    gaCharacterImageTypeSelect,
  };
};
