import { useI18Hook } from 'hooks/useI18Hook';
import notFound from 'assets/images/common/notFound.png';

export const Notfound = () => {
  const { tt } = useI18Hook();
  return (
    <section id="container">
      <div className="content" id="notfound">
        <div className="row">
          <div className="content__charBlock--none">
            <img src={notFound} alt="없는 용병 정보입니다" />
            <p className="content__charBlock--none--title translate">{tt('NotFoundText3')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};
