import { changeLanguage } from 'i18next';
import { useTranslation } from 'react-i18next';
import { languageList } from 'utils/constants';
import { Storage } from 'utils/storage';

export const useI18Hook = () => {
  const { t, i18n } = useTranslation();

  const tt = (s: string) => {
    return t(s);
  };

  const changeLan = (lang: string) => {
    const { setLanguage } = Storage();
    changeLanguage(languageList.find((item) => item.code === lang)?.code || 'en');
    setLanguage(languageList.find((item) => item.code === lang)?.code || 'en');
  };

  const getCurrentLang = () => {
    return i18n.language;
  };

  return {
    changeLan,
    getCurrentLang,
    tt,
  };
};
