import { useGaHook } from 'hooks/useGaHook';
import { useI18Hook } from 'hooks/useI18Hook';
import type { Dispatch, FC, SetStateAction } from 'react';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export const Top10Button: FC<Props> = ({ setVisible, visible }) => {
  const { tt } = useI18Hook();
  const { gaTop10Select } = useGaHook();
  return (
    <div className="row trendModal">
      <button
        type="button"
        className="btn_trendModal "
        onClick={() => {
          setVisible(!visible);
          gaTop10Select();
        }}
      >
        {tt('ViewTop10')}
      </button>
    </div>
  );
};
