import { useI18Hook } from 'hooks/useI18Hook';

export const GatheringData = () => {
  const { tt } = useI18Hook();
  return (
    <div className="usrDataException">
      <div>
        <p>
          <strong>{tt('NoData')}</strong>
          <br />
          {tt('NoDataText')}
        </p>
      </div>
    </div>
  );
};
