import type { CharacterType } from '@types';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { characterState } from 'store/state';
import { useApi } from './useApiHook';

export const useCharacterListHook = () => {
  const [loading, setLoading] = useState(false);
  const [characterData, setCharacterData] = useRecoilState<CharacterType[]>(characterState);
  const { getAllCharacters } = useApi();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const data = await getAllCharacters();
      setCharacterData(data);
      setLoading(false);
    };
    if (characterData.length === 0) getData();
  }, []);

  return {
    loading,
    characterData,
  };
};
