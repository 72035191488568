import type { CharacterDetailType, CharBalanceBasicListType, CharBasicType } from '@types';
import { useCallback, useMemo } from 'react';
import { makeBasicSkillArray, makeCharacterBasicList } from 'utils';
import { getMainSkill, getMisticSkill, getSkillInfoList, getSkillRange } from './charaterInfo';
import { resourceUrl } from './constants';
import misticStar from 'assets/images/object/star/star6_green.png';
import { statHelper } from './statHelper';

export const calcCharacterInfo = (totalData: CharacterDetailType) => {
  // 처음 한번만 구하는 캐릭터 관련 정보들
  const characterInfo: CharBalanceBasicListType = useMemo(
    () => JSON.parse(totalData?.CharBalanceList || '').CharBalanceBasicList,
    []
  );
  const charBasicList = useMemo(() => totalData?.CharBasicList, []);
  const characterStatList: CharBasicType[] = useMemo(
    () => makeCharacterBasicList(charBasicList),
    []
  );
  // 신화 여부
  const isMistic = useMemo(() => Boolean(characterStatList[0]._dynamicSkillBool), []);
  // 일반 스킬 셋팅
  const skillList = useMemo(
    () => getSkillInfoList(charBasicList, makeBasicSkillArray(totalData?.SkillBasicList)),
    []
  );
  // 메인 스킬 셋팅
  const mainSkill = useMemo(() => getMainSkill(totalData, skillList[0].skillBasic), []);

  const misticSkillList = useMemo(
    () =>
      getMisticSkill(totalData?.SkillBasicList, Number(totalData?.CharUniqueCodeList), isMistic),
    []
  );

  const skillRange = useMemo(
    () => getSkillRange(JSON.parse(charBasicList[0].value), skillList[0].skillBasic),
    []
  );

  const { getBattleCharStat } = statHelper(characterInfo, characterStatList);

  // 기본 정보에서 maxLevel, growType등에 정보 추출
  const getCharbasicInfo = useCallback((index: number, reinforce = 0) => {
    const charBasic = characterStatList[index];
    const maxLevel = charBasic._maxLevel + reinforce * 5;
    const currentLevel = charBasic._maxLevel + reinforce * 5;
    const growType = charBasic._growType;
    const star = charBasic._star;
    const type = charBasic._type;

    const statInfo = {
      currentLevel,
      star,
      growType,
      maxLevel,
      type,
    };
    return statInfo;
  }, []);

  // 스타 이미지 정보 추출
  const getImgUrl = useCallback(
    (star: number, growType: number, isMistic: boolean, reinforce: number) => {
      if (isMistic) return misticStar;
      if (reinforce !== 0) return `${resourceUrl}/star/star-reinforce-${reinforce}.png`;
      if (growType === 0) return `${resourceUrl}/star/star-normal-${star}.png`;
      if (growType >= 3) return `${resourceUrl}/star/star-awake-${star}.png`;

      return `${resourceUrl}/star/star-gold-${star}.png`;
    },
    []
  );

  // 캐릭터 기본 정보 셋팅
  const getInitData = useCallback(() => {
    const { currentLevel, star, growType, maxLevel, type } = getCharbasicInfo(0);
    return {
      starImgUrl: getImgUrl(star, growType, isMistic, 0),
      battleStatus: getBattleCharStat(currentLevel, 0),
      maxLevel,
      currentLevel,
      growType,
      star,
      type,
    };
  }, []);

  return {
    getBattleCharStat,
    getCharbasicInfo,
    isMistic,
    characterStatList,
    mainSkill,
    skillList,
    misticSkillList,
    getInitData,
    getImgUrl,
    skillRange,
  };
};
