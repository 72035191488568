import { useGaHook } from 'hooks/useGaHook';
import { useI18Hook } from 'hooks/useI18Hook';
import type { FC } from 'react';

interface Props {
  snsItem: {
    name: string;
    desc: string;
  };
}
export const SnsItem: FC<Props> = ({ snsItem }) => {
  const { tt } = useI18Hook();
  const { gaCommunitySelect } = useGaHook();

  if (tt(snsItem.name))
    return (
      <li className={`floatMenu_${snsItem.name}`}>
        <a
          href={tt(snsItem.name)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => gaCommunitySelect(snsItem.desc + '선택')}
        ></a>
      </li>
    );

  return <></>;
};
