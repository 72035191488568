import type { TipInfo } from '@types';
import { useI18Hook } from 'hooks/useI18Hook';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useState } from 'react';
import { getColorObject, nameColorText } from 'utils';
import { getTooltipDetail } from 'utils/charaterInfo';
import { resourceUrl } from 'utils/constants';

interface Props {
  popupData: TipInfo[];
  setVisible: Dispatch<SetStateAction<boolean>>;
  visible: boolean;
}
export const SkillTooltipPopup: FC<Props> = ({ popupData, setVisible, visible }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { tt } = useI18Hook();
  const tipInfoList = popupData.map((i) => getTooltipDetail(i));

  return visible ? (
    <div id="popup">
      <div
        className="popup_background"
        onClick={() => {
          setActiveIndex(0);
          setVisible(false);
        }}
      ></div>
      <div id="popup-container">
        <div className="popup__header">
          <h2>
            <span
              style={{
                color: tipInfoList[activeIndex]
                  ? getColorObject(tipInfoList[activeIndex].name).color
                  : 'red',
              }}
            >
              {tipInfoList[activeIndex] ? getColorObject(tipInfoList[activeIndex].name).text : ''}
            </span>
          </h2>
          <p>
            {tt('SkillConsistsPrefix')} {tipInfoList.length} {tt('SkillConsistsSuffix')}
          </p>
          <div className="popup__header__skillList">
            {tipInfoList.map((i, ii) => {
              return (
                <div
                  key={'popup' + ii}
                  onClick={() => setActiveIndex(ii)}
                  className={`popup__header__skillList--div popup__header__skillList--type1 ${
                    ii === activeIndex && 'skillup'
                  }`}
                >
                  <button type="button">
                    <img src={`${resourceUrl}/skill/${i.icon}.png`} alt="" />
                  </button>
                </div>
              );
            })}
            {Array(4 - tipInfoList.length)
              .fill('')
              .map(() => {
                return (
                  <div
                    className="popup__header__skillList--div popup__header__skillList--type3"
                    key={Math.random()}
                  >
                    <button type="button">
                      <img
                        src="https://ic-common.pmang.cloud/static/bdt_book/skill/undefined_skill.png"
                        alt=""
                      />
                    </button>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="popup__content">
          <h3>
            <span
              style={{
                color: tipInfoList[activeIndex]
                  ? getColorObject(tipInfoList[activeIndex].name).color
                  : 'red',
              }}
            >
              {tipInfoList[activeIndex] ? getColorObject(tipInfoList[activeIndex].name).text : ''}
            </span>
          </h3>
          <div className="popup__content__summary">
            <ul>
              <li>
                <span>
                  {tipInfoList[activeIndex] ? tipInfoList[activeIndex].skillTypeText : ''}
                </span>
              </li>
              <li>
                <span>
                  {tipInfoList[activeIndex] ? tipInfoList[activeIndex].applyTimeText : ''}
                </span>
              </li>
              <li>
                <span>{tipInfoList[activeIndex] ? tipInfoList[activeIndex].turnText : ''}</span>
              </li>
              <li>
                <span>{tipInfoList[activeIndex] ? tipInfoList[activeIndex].targetText : ''}</span>
              </li>
            </ul>
          </div>

          <div className="popup__content__description">
            <div
              dangerouslySetInnerHTML={{
                __html: tipInfoList[activeIndex]
                  ? nameColorText(tipInfoList[activeIndex].help)
                  : '',
              }}
            ></div>
            {tipInfoList[activeIndex].exception !== 'None' && (
              <>
                <h4>{tt('Exception')}</h4>
                <div>
                  {tipInfoList[activeIndex] &&
                    tipInfoList[activeIndex].exception.split('\\n').map((line) => {
                      return (
                        <span key={Math.random()}>
                          {line}
                          <br />
                        </span>
                      );
                    })}
                </div>
              </>
            )}
          </div>
        </div>
        <div
          className="popup__close"
          onClick={() => {
            setActiveIndex(0);
            setVisible(false);
          }}
        >
          <button type="button"></button>
        </div>
      </div>
      <div id="popup-canvas"></div>
    </div>
  ) : (
    <></>
  );
};
