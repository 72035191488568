import { CharacterInfo } from 'components/detail/CharacterInfo';
import { CharaterImage } from 'components/detail/CharaterImage';
import { DetailHeader } from 'components/detail/DetailHeader';
import { Loading } from 'components/layout/Loading';
import { PageTab } from 'components/layout/PageTab';
import { useDetailCharacter } from 'hooks/useDetailCharacter';
import { useDomChangeHook } from 'hooks/useDomChangeHook';
import { useI18Hook } from 'hooks/useI18Hook';
import { useParams } from 'react-router-dom';
import { getCharName } from 'utils';

export const Detail = () => {
  const { id } = useParams();
  const { tt } = useI18Hook();
  const { renderOk, rankData, imageInfo, characterBasic, totalData, helperInfo } =
    useDetailCharacter(id || '');

  useDomChangeHook(
    `${getCharName(characterBasic) || ''} ${tt('DetailTitle')} - Master Your Tactics, BROWNDUST`
  );

  if (renderOk)
    return (
      <section id="container" className="detailpage">
        <PageTab currentTab={'MercenaryEncyclopedia'} />
        <div className="content" id="detail">
          <div className="container_section">
            <DetailHeader rankData={rankData} id={id || ''} />
            <div className="row">
              <CharaterImage
                imageInfo={imageInfo}
                characterBasic={characterBasic}
                helperInfo={helperInfo}
              />
              <CharacterInfo totalData={totalData} charName={getCharName(characterBasic) || ''} />
            </div>
          </div>
        </div>
      </section>
    );

  return (
    <>
      <section id="container" className="detailpage">
        <PageTab currentTab={'MercenaryEncyclopedia'} />
        <DetailHeader rankData={rankData} id={id || ''} />
      </section>
      <Loading />
    </>
  );
};
