import { MisticSkillType, UpgradeSkillType } from '@types';
import { useI18Hook } from 'hooks/useI18Hook';
import { Dispatch, FC, SetStateAction } from 'react';
import { getSkillText, nameColorText } from 'utils';
import { resourceUrl } from 'utils/constants';

interface Props {
  skill: MisticSkillType;
  setUpgradeArray: Dispatch<SetStateAction<UpgradeSkillType[]>>;
  activeSkillIndex: { row: number; colum: number };
  setActiveSkillIndex: Dispatch<SetStateAction<{ row: number; colum: number }>>;
  skillRange: string;
}

export const MisticFirstSkill: FC<Props> = ({
  skill,
  setUpgradeArray,
  activeSkillIndex,
  setActiveSkillIndex,
  skillRange,
}) => {
  const { tt } = useI18Hook();

  return (
    <li className="mistic-skill-row">
      <div className="skill-text title">
        <span className="mr10 tc">
          {tt('Skill1')}
          <br /> ({tt('Fixed')})
        </span>
        <div className="skill-icon range">
          <button type="button">
            <img src={skillRange} alt="" />
          </button>
        </div>
      </div>
      <div
        className={`skill-icon ${
          activeSkillIndex.colum === 0 && activeSkillIndex.row === 0 && 'skillup'
        }`}
      >
        <button
          type="button"
          onClick={() => {
            setUpgradeArray(skill.skillDetail[0]);
            setActiveSkillIndex({ row: 0, colum: 0 });
          }}
        >
          <img src={`${resourceUrl}/skill/${skill.skillInfo[0].imgUrl}.png`} alt="" />
        </button>
      </div>
      <div className="skill-text desc ml10">
        <span
          className="skill-name-desc"
          dangerouslySetInnerHTML={{
            __html: nameColorText(getSkillText(skill.skillInfo[0])),
          }}
        ></span>
      </div>
    </li>
  );
};
