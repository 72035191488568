import type { WinOrPickRatioType } from '@types';
import { useI18Hook } from 'hooks/useI18Hook';
import type { FC } from 'react';
import { grapListGenerator } from 'utils';
import { tierCode } from 'utils/constants';
import { BarGrap } from './BarGrap';

interface Props {
  pickOrWinRatioList: { [key: number]: WinOrPickRatioType[] };
  winOrPick: string;
  contentType: number;
}
export const GradeGrap: FC<Props> = ({ pickOrWinRatioList, winOrPick, contentType }) => {
  const { tt } = useI18Hook();

  return (
    <div className="col web-pl30">
      <div className="content__info mln">
        <h3 className="substring ">{tt('UserDataTitle5')}</h3>
        <div className="box" id="box_graph2">
          <table>
            <thead>
              <tr>
                <th rowSpan={2}>{tt('Grade')}</th>
                <th className="gradeChartsTitle" colSpan={2}>
                  {tt(winOrPick)}
                </th>
              </tr>
              <tr>
                <th>{tt('Attack')}</th>
                <th>{tt('Defense')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <ul className="grade-header">
                    {tierCode
                      .filter((i) => i.code !== 0)
                      .map((ii) => (
                        <li key={ii.code + 'tierCode'}>{tt(ii.label)}</li>
                      ))}
                  </ul>
                </th>
                <td>
                  <ul className="grade-header">
                    {grapListGenerator(pickOrWinRatioList[1], winOrPick, contentType).map(
                      (i, index) => {
                        return <BarGrap key={index + 'rate-sort-attack'} rate={i.rate} />;
                      }
                    )}
                  </ul>
                </td>
                <td>
                  <ul className="grade-header">
                    {grapListGenerator(pickOrWinRatioList[2], winOrPick, contentType).map(
                      (i, index) => {
                        return (
                          <BarGrap key={index + 'rate-sort-gard'} rate={i.rate} type={'type2'} />
                        );
                      }
                    )}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
