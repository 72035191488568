import { useI18Hook } from 'hooks/useI18Hook';
import type { FC } from 'react';

interface Props {
  submenu: {
    link: string;
    title: string;
  };
}
export const SubMenuItem: FC<Props> = ({ submenu }) => {
  const { tt } = useI18Hook();
  return (
    <li>
      <a href={submenu.link} rel="noreferrer noopener">
        <span>{tt(submenu.title)}</span>
      </a>
    </li>
  );
};
