import type { RankSingle } from '@types';
import { useI18Hook } from 'hooks/useI18Hook';
import type { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

interface Props {
  rankData: RankSingle | undefined;
  id: string;
}

export const DetailHeader: FC<Props> = ({ rankData, id }) => {
  const navigate = useNavigate();
  const { tt } = useI18Hook();

  return (
    <div className="row container_section--title mb-20">
      <div className="col-12">
        <div className="mainstring">
          <i></i>
          <span className="">{tt('UserDataInfo')}</span>
          <Link
            to={`/battlebookInfo/${id}?current=${rankData?.recent.rate}&before=${
              rankData?.before.rate
            }&previous=${rankData?.previous.rate}&rank=${
              Number(rankData?.recentIndex) + 1
            }&isUpDown=${rankData?.upDown.value}&isUpDownStatus=${rankData?.upDown.status}`}
          >
            <button className="goBattleBook">{tt('BattleBookDataInfo')}</button>
          </Link>

          <div className="goList" onClick={() => navigate('/mercsindex')}></div>
        </div>
      </div>
    </div>
  );
};
