import type { CharacterType, ChartType, Top10Type } from '@types';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { makeChartData } from 'utils';
import { useI18Hook } from 'hooks/useI18Hook';
import { Legend } from './Legend';
import { LineGraph } from './LineGraph';

interface Props {
  top10List: Top10Type;
  characterData: CharacterType[];
}

export const ToptenModal: FC<Props> = ({ characterData, top10List }) => {
  const { tt } = useI18Hook();
  const [lineData, setLineData] = useState<ChartType[]>([]);
  const [legends, setLegends] = useState<{ color: string; label: string }[]>([]);
  useEffect(() => {
    if (top10List) {
      const { chartData, legendList } = makeChartData(top10List, characterData);
      setLegends(legendList);
      setLineData(chartData);
    }
  }, [characterData, top10List]);

  return (
    <div id="browndust_trendLine_modal">
      <div className="browndust_trendLine_modal__head">
        <h2 className="browndust_trendLine_modal__head--title">
          {tt('Top10PopularMercenary')} {tt('Top10PopularMercenarySub')}
        </h2>
        <div className="browndust_trendLine_modal__head--labels">
          {legends.map((legend) => (
            <Legend key={legend.label} legend={legend} />
          ))}
        </div>
      </div>
      <div className="browndust_trendLine_modal__content">
        <div className="browndust_trendLine_modal__content-head">
          <div>{tt('WinRate')}</div>
          <div>{tt('Trend')}</div>
        </div>
      </div>

      <div className="line-grap" style={{ height: 500 }}>
        <LineGraph lineData={lineData} />
      </div>
    </div>
  );
};
