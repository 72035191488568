import type { Dispatch, FC, SetStateAction } from 'react';
import { useI18Hook } from 'hooks/useI18Hook';
import { languageList } from 'utils/constants';

interface Props {
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export const SelectLanguagePopup: FC<Props> = ({ setVisible }) => {
  const { changeLan, getCurrentLang } = useI18Hook();
  const isActive = (lang: string) => lang === getCurrentLang() && 'active';

  const closePopup = () => {
    setVisible(false);
  };

  return (
    <div className="setLanguagePopup">
      <div className="setLanguagePopup_content">
        <button className="setLanguagePopup_content-close" onClick={closePopup}></button>
        <h3>
          <span>Choose your language</span>
        </h3>
        <ul>
          {languageList.map((lang) => (
            <li
              key={lang.code + 'lang'}
              onClick={() => {
                changeLan(lang.code);
                closePopup();
              }}
            >
              <button className={`${isActive(lang.code)}`}>{lang.label}</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
