import type { SkillFilterCharacterTab } from '@types';
import { useGaHook } from 'hooks/useGaHook';
import type { Dispatch, FC, SetStateAction } from 'react';
import { getTabName } from 'utils';

interface Props {
  tabInfo: SkillFilterCharacterTab;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  setMobileskillMenuVisible: Dispatch<SetStateAction<boolean>>;
}

export const SkillTab: FC<Props> = ({
  tabInfo,
  activeTab,
  setActiveTab,
  setMobileskillMenuVisible,
}) => {
  const { gaSkillCategorySelect } = useGaHook();
  return (
    <li key={tabInfo._tabNameCode}>
      <button
        type="button"
        className={`button type5 ${tabInfo._tabNameCode === activeTab && 'active'}`}
        data-skillcode={tabInfo._tabNameCode}
        onClick={() => {
          if (window.innerWidth < 768) setMobileskillMenuVisible(false);
          setActiveTab(tabInfo._tabNameCode);
          gaSkillCategorySelect(getTabName(tabInfo));
          window.scrollTo(0, 300);
        }}
      >
        {getTabName(tabInfo)}
      </button>
    </li>
  );
};
