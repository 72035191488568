import { useI18Hook } from 'hooks/useI18Hook';
import type { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  currentTab: string;
  label: string;
  to: string;
}
export const Tab: FC<Props> = ({ currentTab, label, to }) => {
  const { tt } = useI18Hook();
  return (
    <div className="col-4 pr-10">
      <Link to={to} className={`button type1 ${currentTab === label && 'active'} `}>
        {tt(label)}
      </Link>
    </div>
  );
};
