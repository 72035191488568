import type { FilterCharacterTab, CharTypeNumber } from '@types';
import { Loading } from 'components/layout/Loading';
import { PageTab } from 'components/layout/PageTab';
import { CharacterItem } from 'components/mercsindex/CharacterItem';
import { CharacterFilter } from 'components/mercsindex/CharacterFilter';
import { useCharacterListHook } from 'hooks/useCharacterListHook';
import { useDomChangeHook } from 'hooks/useDomChangeHook';
import { useI18Hook } from 'hooks/useI18Hook';
import { useState } from 'react';
import { getCharName } from 'utils';
import { useSearchParams } from 'react-router-dom';

export const MercsIndex = () => {
  const { tt } = useI18Hook();
  const { loading, characterData } = useCharacterListHook();
  const [searchParams] = useSearchParams();
  const [searchText, setSearchText] = useState('');
  const [currentTab, setCurrentTab] = useState<FilterCharacterTab>({
    label: 'mistic',
    filterType: Number(searchParams.get('tabCode')) as CharTypeNumber,
  });
  useDomChangeHook(`${tt('ListTitle')}`);

  return (
    <section id="container">
      {loading && <Loading />}
      <PageTab currentTab={'MercenaryEncyclopedia'} />
      <CharacterFilter
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <div className="content" id="list">
        <div className="row">
          {characterData
            .filter((char) => {
              // 검색어가 있을 경우 필터링 하지 않고, 다른 필터링을 함
              if (searchText.length > 0) {
                // 검색에 콜라보 용병이 노출 되지 않도록
                if (char._uiIconImageName && char._uiIconImageName.includes('Collabo')) {
                  return false;
                }
                const charName = getCharName(char) || '';
                // BROWNDUST-3094 : 검색어과 데이터를 모두 소문자로 비교
                return charName.toLowerCase().includes(searchText.toLowerCase());
              }

              return char._filterType === currentTab.filterType;
            })
            .map((item, index) => {
              return <CharacterItem key={`CharacterItem${index}`} CharacterInfo={item} />;
            })}
        </div>
      </div>
    </section>
  );
};
