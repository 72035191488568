import type { CharacterDetailType, CharBasicType, HelperCharInfo, RankSingle } from '@types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRankInfo } from 'utils';
import { getImageInfo } from 'utils/charaterInfo';
import { characterDetailInitData, iniImagePropsInfo } from 'utils/constants';
import { useApi } from './useApiHook';

type ImagePropsInfo = {
  mercenaries: { illust: string; inGame: string };
  companions: { illust: string; inGame: string };
};

export const useDetailCharacter = (id: string) => {
  const navigation = useNavigate();
  const { getDetailCharacters, getDefaultRankingData } = useApi();
  const [renderOk, setRenderOk] = useState(false);
  const [characterBasic, setCharacterBasic] = useState<CharBasicType>();
  const [imageInfo, setImageInfo] = useState<ImagePropsInfo>(iniImagePropsInfo);
  const [totalData, setTotalData] = useState<CharacterDetailType>(characterDetailInitData);
  const [rankData, setRankTypeData] = useState<RankSingle>();
  const [helperInfo, setHelperInfo] = useState<HelperCharInfo>();

  useEffect(() => {
    const getData = async () => {
      const [data, rankData] = await Promise.all([
        getDetailCharacters(id),
        getDefaultRankingData(),
      ]);
      if (!data.CharUniqueCodeList) {
        navigation('/404');
        return;
      }

      const characterBasicInfo: CharBasicType = JSON.parse(data.CharBasicList[0].value);
      const imageInfo = getImageInfo(characterBasicInfo);
      const { before, previous, recentIndex, upDown, recent } = getRankInfo(
        rankData,
        Number(id) || 0
      );

      if (before && previous && recent)
        setRankTypeData({
          recent,
          before,
          previous,
          recentIndex,
          upDown,
        });

      setHelperInfo(characterBasicInfo._helperCharInfo);
      setTotalData(data);
      setImageInfo(imageInfo);
      setCharacterBasic(characterBasicInfo);
      setRenderOk(true);
    };

    getData();
  }, []);

  return {
    renderOk,
    characterBasic,
    imageInfo,
    totalData,
    rankData,
    helperInfo,
  };
};
