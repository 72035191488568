import type { FC } from 'react';
import { resourceUrl } from 'utils/constants';

interface Props {
  rowIndex: number;
  growType: number;
  type: number;
  uiActionNum: string;
  addClass: string;
}

export const RangeSkillIcon: FC<Props> = ({ rowIndex, growType, type, uiActionNum, addClass }) => {
  const numberImgurl = `${resourceUrl}/img/icon/icon_skillLevel__${rowIndex}.png`;
  const rangeImgUrl = `${resourceUrl}/skill/range/range_${uiActionNum}${type === 4 && growType >= 0 ? '_y' : ''
    }.png`;

  return (
    <>
      <div className="content__skillList--div content__skillList--level">
        <span>
          <img src={numberImgurl} alt="+0" />
        </span>
      </div>
      <div className={`content__skillList--div content__skillList--range ${addClass}`}>
        <button type="button">
          <img src={rangeImgUrl} alt="" />
        </button>
      </div>
      <div className="content__skillList--div content__skillList--line"></div>
    </>
  );
};
