import type { AttackOrderType, SkillLevelType } from '@types';
import { useI18Hook } from 'hooks/useI18Hook';
import type { FC } from 'react';
import { fillAttackOrderList, fillSkillLevelList, sortAttack, sortSkill } from 'utils';
import { GatheringData } from 'components/layout/GatheringData';
import { VerticalBarGrap } from './VerticalBarGrap';
import { NotexistData } from 'components/layout/NotexistData';

interface Props {
  attackOrderList: AttackOrderType[];
  skillLevelList: SkillLevelType[];
}

export const AttackSkillGraph: FC<Props> = ({ attackOrderList, skillLevelList }) => {
  const { tt } = useI18Hook();
  return (
    <div className="col">
      <div className="content__info">
        <h3 className="substring ">{tt('UserDataTitle4')}</h3>
        <div className="box" id="box_graph1">
          <dl className="chartSheet1">
            <dt>{tt('SkillLevel')}</dt>
            <dd id="chart1">
              {skillLevelList.length > 0 ? (
                Number(skillLevelList[0].statisticsDetails.rate) === 1 ? (
                  <NotexistData />
                ) : (
                  <div className="bar-wrap">
                    {fillSkillLevelList(skillLevelList)
                      .sort(sortSkill)
                      .map((item, index) => (
                        <VerticalBarGrap
                          key={'skill' + index}
                          rate={item.statisticsDetails.rate}
                          index={index}
                        />
                      ))}
                  </div>
                )
              ) : (
                <GatheringData />
              )}
            </dd>
          </dl>
          <dl className="chartSheet2">
            <dt>{tt('AttackSequence')}</dt>
            <dd id="chart2">
              {attackOrderList.length > 0 ? (
                <div className="bar-wrap">
                  {fillAttackOrderList(attackOrderList)
                    .sort(sortAttack)
                    .map((item, index) => (
                      <VerticalBarGrap
                        key={'skill' + index}
                        rate={item.statisticsDetails.rate}
                        index={index}
                        type={'attack-order'}
                      />
                    ))}
                  ;
                </div>
              ) : (
                <GatheringData />
              )}
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
};
