import { useGaHook } from 'hooks/useGaHook';
import { useI18Hook } from 'hooks/useI18Hook';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { convertBattleType, convertServerName } from 'utils';

interface Props {
  server: number;
  charName: string;
  winOrPick: string;
  setServer: Dispatch<SetStateAction<number>>;
  setWinOrPick: Dispatch<SetStateAction<string>>;
  star: string;
  contentType: number;
  setContentType: Dispatch<SetStateAction<number>>;
}

export const BattlebookFilter: FC<Props> = ({
  server,
  winOrPick,
  setServer,
  setWinOrPick,
  star,
  setContentType,
  contentType,
  charName,
}) => {
  const { battleBookInfoChange } = useGaHook();
  const { tt } = useI18Hook();
  const navigate = useNavigate();
  const serverButton = document.querySelector(
    '.usrDataSelect.type2 .usrDataSelect-button'
  ) as HTMLInputElement;
  const pickButton = document.querySelector(
    '.usrDataSelect.type3 .usrDataSelect-button'
  ) as HTMLInputElement;
  const battleTypeButton = document.querySelector(
    '.usrDataSelect.type1 .usrDataSelect-button'
  ) as HTMLInputElement;

  return (
    <div className="row container_section--title mb-10">
      <div className="col-12">
        <div className="mainstring">
          <i />
          <span>{tt('BattleBookDataInfo')}</span>
          <div className="usrDataSelect-group">
            {Number(star) !== 0 && Number(star) < 5 && (
              <div className="usrDataSelect type1">
                <input type="checkbox" className="usrDataSelect-button" />
                <h3 className="usrDataSelect-title ">
                  {tt(contentType === 1 ? 'Arena' : 'NoviceArena')}
                </h3>
                <div className="usrDataSelect-list">
                  <ul>
                    <li
                      data-value="1"
                      onClick={() => {
                        setContentType(1);
                        // css가 checkbox로 되어 있어, 클릭 이벤트 발생 시킴
                        battleBookInfoChange('결투장', convertServerName(server), charName || '');
                        if (battleTypeButton) battleTypeButton.click();
                      }}
                    >
                      {tt('Arena')}
                    </li>
                    <li
                      data-value="2"
                      onClick={() => {
                        battleBookInfoChange(
                          convertBattleType(contentType),
                          convertServerName(server),
                          charName || ''
                        );
                        setContentType(2);
                        // css가 checkbox로 되어 있어, 클릭 이벤트 발생 시킴
                        if (battleTypeButton) battleTypeButton.click();
                      }}
                    >
                      {tt('NoviceArena')}
                    </li>
                  </ul>
                </div>
              </div>
            )}
            <div className="usrDataSelect type2">
              <input type="checkbox" className="usrDataSelect-button" />
              <h3 className="usrDataSelect-title ">{tt(`Server${server}`)}</h3>
              <div className="usrDataSelect-list">
                <ul>
                  <li
                    data-value="99"
                    onClick={() => {
                      setServer(99);
                      battleBookInfoChange(
                        convertBattleType(contentType),
                        'Origin',
                        charName || ''
                      );
                      // css가 checkbox로 되어 있어, 클릭 이벤트 발생 시킴
                      if (serverButton) serverButton.click();
                    }}
                  >
                    {tt('Server99')}
                  </li>
                  <li
                    data-value="100"
                    onClick={() => {
                      setServer(100);
                      battleBookInfoChange(convertBattleType(contentType), 'Terra', charName || '');
                      // css가 checkbox로 되어 있어, 클릭 이벤트 발생 시킴
                      if (serverButton) serverButton.click();
                    }}
                  >
                    {tt('Server100')}
                  </li>
                </ul>
              </div>
            </div>
            <div className="usrDataSelect type3">
              <input type="checkbox" className="usrDataSelect-button" />
              <h3 className="usrDataSelect-title ">{tt(winOrPick)}</h3>
              <div className="usrDataSelect-list">
                <ul>
                  <li
                    data-value="1"
                    onClick={() => {
                      setWinOrPick('PickRate');
                      // css가 checkbox로 되어 있어, 클릭 이벤트 발생 시킴
                      if (pickButton) pickButton.click();
                    }}
                  >
                    {tt('PickRate')}
                  </li>
                  <li
                    data-value="2"
                    onClick={() => {
                      setWinOrPick('WinRate');
                      // css가 checkbox로 되어 있어, 클릭 이벤트 발생 시킴
                      if (pickButton) pickButton.click();
                    }}
                  >
                    {tt('WinRate')}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="goList" onClick={() => navigate('/')} />
        </div>
      </div>
    </div>
  );
};
