import { useI18Hook } from 'hooks/useI18Hook';
import type { FC } from 'react';
import { useGaHook } from 'hooks/useGaHook';
import { SetterOrUpdater } from 'recoil';
import type { fillterTitleType, StateType } from '@types';

interface Props {
  item: {
    id: string;
    value: number;
    callback: (setState: SetterOrUpdater<StateType>, value: number, state: StateType) => void;
  };
  name: string;
  setState: SetterOrUpdater<StateType>;
  state: StateType;
  fillterTitle: fillterTitleType;
}

export const RadioButton: FC<Props> = ({ item, name, setState, state, fillterTitle }) => {
  const { gaChangeBattleBookOption } = useGaHook();
  const { callback, id, value } = item;
  const checkedCode = state[fillterTitle];
  const { tt } = useI18Hook();

  return (
    <li>
      <label htmlFor={id}>
        <span className="input-deco-radio">
          <input
            type="radio"
            value={value}
            id={id}
            name={name}
            defaultChecked={Number(checkedCode) === value}
            onChange={({ target: { value } }) => {
              gaChangeBattleBookOption();
              callback(setState, Number(value), state);
            }}
          />
          <span></span>
        </span>
        <span>{tt(id)}</span>
      </label>
    </li>
  );
};
