import type { CloseCharDetailType } from '@types';
import { GatheringData } from 'components/layout/GatheringData';
import { useI18Hook } from 'hooks/useI18Hook';
import type { FC } from 'react';
import { thumbnailUrl } from 'utils/constants';
import { CloseCharIcon } from './CloseCharIcon';

interface Props {
  closeCharImg: CloseCharDetailType[];
}

export const CloseCharacter: FC<Props> = ({ closeCharImg }) => {
  const { tt } = useI18Hook();

  return (
    <div className="col-w-350">
      <div className="content__info mln">
        <h3 className="substring ">{tt('UserDataTitle1')}</h3>
        <div className="box" id="box_favoriteMercenary">
          {closeCharImg.length > 0 ? (
            <ul>
              {closeCharImg.map((item, index) => {
                const imgInfo = item.imgInfo || '';
                const iconUrl = `${thumbnailUrl}/${imgInfo.split('*')[1]}.png`;
                if (index > 7) return null;
                return (
                  <CloseCharIcon
                    key={'CloseCharIcon' + index}
                    icon={iconUrl}
                    index={index}
                    closeCharCode={item.closeCharCode}
                  />
                );
              })}
            </ul>
          ) : (
            <GatheringData />
          )}
        </div>
      </div>
    </div>
  );
};
