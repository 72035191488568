import type { FilterCharacterTab, CharTypeNumber } from '@types';
import type { Dispatch, FC, SetStateAction } from 'react';
import { tabList } from 'utils/constants';
import { FilterTab } from './FilterTab';
import { SearchInput } from './SearchInput';

interface Props {
  currentTab: FilterCharacterTab;
  setCurrentTab: Dispatch<SetStateAction<FilterCharacterTab>>;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
}

export const CharacterFilter: FC<Props> = ({
  currentTab,
  setCurrentTab,
  setSearchText,
  searchText,
}) => {
  const activeClass = (filterType: CharTypeNumber) => {
    if (currentTab.filterType === filterType && searchText.length === 0) return 'active';
    return '';
  };

  return (
    <>
      <SearchInput setSearchText={setSearchText} />
      <div className="button_group row select_charType">
        {tabList.map((item, index) => {
          return (
            <div
              className={`col-2 pr-10 tab ${item.label.toLocaleLowerCase()}`}
              key={'filterTab' + index}
            >
              <FilterTab
                filterType={item.code}
                label={item.label}
                setCurrentTab={setCurrentTab}
                setSearchText={setSearchText}
                activeClass={activeClass}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
