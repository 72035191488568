import axios from 'axios';
import type {
  CharacterDetailType,
  CharacterType,
  CloseCharDetailType,
  RankTypeData,
  SkillDictionaryBasicListType,
  StateType,
  StatisticsType,
  Top10Type,
} from '@types';
import { useCallback } from 'react';
import { makeUrl, makeUrlWidthState } from 'utils';

export function useApi() {
  const getWoldBossRankList = useCallback(async (state: StateType) => {
    const targetUrl = `/v1/statistics/rank/worldBoss/getAll?serverCode=${state.serverCode}&bossCode=${state.bossCode}&dataCode=${state.dataCode}`;
    const url = makeUrl(targetUrl);
    const { data } = await axios.get<RankTypeData>(url);
    return data;
  }, []);

  const getDefaultRankingData = useCallback(async () => {
    const targetUrl =
      '/v1/statistics/rank/battle/getAll?serverCode=99&contentCode=1&tierCode=1&dataCode=11&attackCode=0&rankCode=1';
    const url = makeUrl(targetUrl);
    const { data } = await axios.get<RankTypeData>(url);
    return data;
  }, []);

  const getAllCharacters = useCallback(async () => {
    const targetUrl = '/v1/book/character/getAll';
    const url = makeUrl(targetUrl);
    const { data } = await axios.get<CharacterType[]>(url);
    return data;
  }, []);

  const getRankList = useCallback(async (state: StateType) => {
    const targetUrl = '/v1/statistics/rank/battle/getAll';
    const url = makeUrlWidthState(targetUrl, state);
    const { data } = await axios.get<RankTypeData>(url);
    return data;
  }, []);

  const getSkillList = useCallback(async () => {
    const targetUrl = '/v1/book/skill/list';
    const url = makeUrl(targetUrl);
    const {
      data: { SkillDictionaryBasicList },
    } = await axios.get<SkillDictionaryBasicListType>(url);

    return SkillDictionaryBasicList;
  }, []);

  const getDetailCharacters = useCallback(async (id: string) => {
    const targetUrl = `/v1/book/character/get?code=${id}`;
    const url = makeUrl(targetUrl);
    const { data } = await axios.get<CharacterDetailType>(url);
    return data;
  }, []);

  const getStatistics = useCallback(async (id: string, server: number) => {
    const targetUrl = `/v1/statistics/get?serverCode=${server}&charCode=${id}`;
    const url = makeUrl(targetUrl);
    const { data } = await axios.get<StatisticsType>(url);
    return data;
  }, []);

  // 배틀북 상세, 주로 함께 사용하는 용병 ID 리스트
  const getCloseCharacter = useCallback(async (list: CloseCharDetailType[]) => {
    const resultArray = [];
    const targetUrl = `/v1/book/thumb/list`;
    const url = makeUrl(targetUrl);
    const params = list.map((i) => i.closeCharCode);
    const { data } = await axios.post<string[]>(url, params);
    for (let i = 0; i < list.length; i++) {
      resultArray[i] = {
        ...list[i],
        imgInfo: data[i],
      };
    }
    return resultArray;
  }, []);

  const getTop10Character = useCallback(async (state: StateType) => {
    const targetUrl = `/v1/statistics/recent/battle/top10?serverCode=${state.serverCode}&contentCode=${state.contentCode}&tierCode=${state.tierCode}&attackType=${state.attackCode}&rankCode=${state.rankCode}`;
    const url = makeUrl(targetUrl);
    const { data } = await axios.get<Top10Type>(url);
    return data;
  }, []);

  return {
    getAllCharacters,
    getRankList,
    getSkillList,
    getDetailCharacters,
    getStatistics,
    getCloseCharacter,
    getDefaultRankingData,
    getWoldBossRankList,
    getTop10Character,
  };
}
