import { useI18Hook } from 'hooks/useI18Hook';
import { RankItem } from 'components/main/RankItem';
import { PageTab } from 'components/layout/PageTab';
import { Loading } from 'components/layout/Loading';
import { BattleBookFilter } from 'components/main/BattleBookFilter';
import { ToptenModal } from 'components/main/ToptenModal';
import { useRankHook } from 'hooks/useRankHook';
import { NoTier } from 'components/main/NoTier';
import { useDomChangeHook } from 'hooks/useDomChangeHook';
import { useState } from 'react';
import { Top10Button } from 'components/main/Top10Button';

export const Main = () => {
  const { tt } = useI18Hook();
  useDomChangeHook(`${tt('BattleBookListTitle')}`);
  const [visible, setVisible] = useState(false);
  const { setState, loading, state, rankListData, characterData, top10List } = useRankHook();

  return (
    <section id="container" className="battlebookpage">
      <PageTab currentTab="BattleBookEncyclopedia" />
      <BattleBookFilter state={state} setState={setState} isWoldBoss={state.isWoldBoss} />
      <Top10Button visible={visible} setVisible={setVisible} />
      <div className="content" id="battleList">
        {visible && <ToptenModal top10List={top10List} characterData={characterData} />}
        <div className="row">
          <div className="battleListTable">
            {loading && <Loading />}
            <table>
              <thead>
                <tr>
                  <th colSpan={2}>
                    <span>No.</span>
                  </th>
                  <th>
                    <span>{tt('Mercenaries')}</span>
                  </th>
                  <th>
                    <span>{tt(state.rankCode === 1 ? 'PickRate' : 'WinRate')}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {rankListData.RECENT.map((rankItem, index) => (
                  <RankItem
                    isWinRate={state.rankCode}
                    isWoldBoss={state.isWoldBoss}
                    key={`list-item${Math.random()}`}
                    rankListData={rankListData}
                    index={index}
                    charInfo={characterData.find(
                      (characterItem) => characterItem._uniqueCode === rankItem.charCode
                    )}
                    currentInfo={rankItem}
                    content={state.contentCode}
                    serverCode={state.serverCode}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {rankListData.RECENT.length === 0 && !loading && <NoTier />}
      </div>
    </section>
  );
};
