import type {
  CharacterType,
  CharBasicTypeListType,
  CloseCharType,
  DoubleNumberToStringObj,
  MainSkillNameType,
  CloseCharDetailType,
  SkillLevelType,
  AttackOrderType,
  RankTypeData,
  WinOrPickRatioType,
  Top10Type,
  SkillFilterCharacterTab,
  CharBasicType,
  NumberToSkillObj,
  ChartType,
  ChartDataType,
  RankType,
  RuneType,
  MultiLanguageName,
  MultiLangugeCharName,
  StateType,
} from '@types';
import {
  ColorArray,
  ENV,
  globalDqUrl,
  globalLiveUrl,
  koDqUrl,
  koLiveUrl,
  resourceUrl,
} from './constants';
import { Storage } from './storage';

const env = import.meta.env ? import.meta.env.VITE_APP_ENV : 'development';

export const getBaseUrl = () => {
  const { getLanguage } = Storage();

  if (getLanguage() === 'ko') {
    if (env === ENV.PRODUCTION) return koLiveUrl;
    return koDqUrl;
  }

  if (env === ENV.PRODUCTION) return globalLiveUrl;
  return globalDqUrl;
};

export const makeUrl = (targetUrl: string) => {
  return getBaseUrl() + targetUrl;
};

const makeParams = (state: StateType) => {
  return `serverCode=${state.serverCode}&contentCode=${state.contentCode}&tierCode=${state.tierCode}&dataCode=${state.dataCode}&attackCode=${state.attackCode}&rankCode=${state.rankCode}`;
};

// state 객체를 이용하여 url을 만들어 주는 함수
export const makeUrlWidthState = (targetUrl: string, state: StateType) => {
  let url = getBaseUrl() + targetUrl;
  url = `${url}?${makeParams(state)}`;
  return url;
};

export const getRateText = (currentInfo: RankType) => {
  const text = (Number(currentInfo.rate) * 100).toFixed(2) + '%';
  if (text === '0.00%') {
    return '0.01% ▼';
  }
  return text;
};

export const getImgUrl = (charInfo?: CharacterType) => {
  const thumbnailType = charInfo?._uiIconImageName.split('*')[1];
  if (thumbnailType) return `${resourceUrl}/thumbnail/${thumbnailType}.png`;
  return '';
};

export const getCharaterUrl = (charInfo?: CharacterType) => {
  const thumbnailType = charInfo?._uiIconImageName.split('*')[1].split('icon')[0];
  return `${resourceUrl}/character/${thumbnailType}.png`;
};

export const convertServerName = (server: number) => {
  if (server === 99) return 'Origin';
  return 'Terra';
};

export const convertBattleType = (battleType: number) => {
  if (battleType === 1) return '결투장';
  return '신성결투장';
};

export const getCharName = <T extends MultiLangugeCharName>(charInfo: T | undefined) => {
  const { getLanguage } = Storage();
  switch (getLanguage()) {
    case 'ko':
      return charInfo?._charName;
    case 'en':
      return charInfo?._charName_ENG;
    case 'ja':
      return charInfo?._charName_JAP;
    case 'zh':
      return charInfo?._charName_TW;
  }
};

export const getSkillText = <T extends MultiLanguageName>(nameObj: T): string => {
  const { getLanguage } = Storage();
  switch (getLanguage()) {
    case 'ko':
      return nameObj._name;
    case 'en':
      return nameObj._name_ENG;
    case 'ja':
      return nameObj._name_JAP;
    case 'zh':
      return nameObj._name_TW;
  }

  return '';
};

export const getMainSkillRangeName = (skillRangeName: MainSkillNameType) => {
  const { getLanguage } = Storage();
  switch (getLanguage()) {
    case 'ko':
      return skillRangeName._nameText;
    case 'en':
      return skillRangeName._nameText_ENG;
    case 'ja':
      return skillRangeName._nameText_JAP;
    case 'zh':
      return skillRangeName._nameText_TW;
  }

  return '';
};

export const getTabName = <T extends SkillFilterCharacterTab>(tabObj: T | undefined) => {
  const { getLanguage } = Storage();
  if (tabObj)
    switch (getLanguage()) {
      case 'ko':
        return tabObj._tabName;
      case 'en':
        return tabObj._tabName_ENG;
      case 'ja':
        return tabObj._tabName_JAP;
      case 'zh':
        return tabObj._tabName_TW;
    }

  return '';
};

// 컬러와 텍스트가 같이 있는 문자열 파싱 함수
// ex) [7CDB00]Death Guard[-]
export const getColorObject = (txt = '') => {
  return {
    color: '#' + txt.split('[')[1].split(']')[0],
    text: txt.split('[')[1].split(']')[1],
  };
};

export const isEmptyObject = (value: object) => {
  return Object.keys(value).length === 0;
};

//
export const makeCharacterBasicList = (list: CharBasicTypeListType[] | undefined) => {
  const array: CharBasicType[] = [];
  if (list) {
    for (const character of list) {
      const data = JSON.parse(character.value);
      array.push(data);
    }
  }

  return array;
};

/* 

{
  4305: {
    430400: {"{\"_code\":430400,\"_nameCode\":43040071,\"_isBoss\":0,\"_uiActionNum\":2...}"},
    430401: {"{\"_code\":430400,\"_nameCode\":43040071,\"_isBoss\":0,\"_uiActionNum\":2...}"},
    430402: {"{\"_code\":430400,\"_nameCode\":43040071,\"_isBoss\":0,\"_uiActionNum\":2...}"},
    430403: {"{\"_code\":430400,\"_nameCode\":43040071,\"_isBoss\":0,\"_uiActionNum\":2...}"},
  },
  4306: {
    430400: {"{\"_code\":430400,\"_nameCode\":43040071,\"_isBoss\":0,\"_uiActionNum\":2...}"},
    430401: {"{\"_code\":430400,\"_nameCode\":43040071,\"_isBoss\":0,\"_uiActionNum\":2...}"},
    430402: {"{\"_code\":430400,\"_nameCode\":43040071,\"_isBoss\":0,\"_uiActionNum\":2...}"},
    430403: {"{\"_code\":430400,\"_nameCode\":43040071,\"_isBoss\":0,\"_uiActionNum\":2...}"},
  },
  ....
}

위 객체를 아래 객체와 같이 변환 하는 함수
{
  430400: {_code: 430400, _nameCode: 43040071, _isBoss: 0, _uiActionNum: 23, _battleValueMultiply: 1, …}
  430401: {_code: 430401, _nameCode: 43040071, _isBoss: 0, _uiActionNum: 23, _battleValueMultiply: 1, …}
  430402: {_code: 430402, _nameCode: 43040071, _isBoss: 0, _uiActionNum: 23, _battleValueMultiply: 1, …}
  ....
}
*/
export const makeBasicSkillArray = (list: DoubleNumberToStringObj | undefined) => {
  const obj: NumberToSkillObj = {};
  for (const skillIndex in list) {
    const skillContainer = list[Number(skillIndex)];
    for (const skill in skillContainer) {
      const skillInfo = JSON.parse(skillContainer[skill]);
      if (!obj[skill]) obj[skill] = skillInfo;
    }
  }

  return obj;
};

export const getThumbnailCodeList = (list: CloseCharType[]): CloseCharDetailType[] => {
  if (list && list.length > 0)
    return list.filter((i) => i.statisticsDetails.tier === '0').map((i) => i.statisticsDetails);

  return [];
};

// 랭크 정보가 순서 대로 내려 오기 때문에 index를 기준으로 랭크를 구할 수 있음
// index차를 통해 upDown 여부 확인 가능
export const getRankInfo = (listData: RankTypeData, charCode: number) => {
  const previousIndex = listData.PREVIOUS.findIndex((prev) => prev.charCode === charCode);
  const recentIndex = listData.RECENT.findIndex((prev) => prev.charCode === charCode);
  const result = {
    before: listData.BEFORE.find((prev) => prev.charCode === charCode),
    previous: listData.PREVIOUS.find((prev) => prev.charCode === charCode),
    recent: listData.RECENT.find((prev) => prev.charCode === charCode),
    recentIndex,
    upDown: getUpDownStatus(previousIndex, recentIndex),
  };
  return result;
};

// upDown 여부와 얼마만큼에 upDown 되었는 지 체크
export const getUpDownStatus = (previousIndex: number, index: number) => {
  if (previousIndex === -1) return { status: 'same', value: 'NEW' };
  if (index === previousIndex) return { status: 'same', value: '-' };
  if (index > previousIndex) return { status: 'down', value: String(index - previousIndex) };
  if (index < previousIndex) return { status: 'up', value: String(previousIndex - index) };
  return { status: 'same', value: '-' };
};

export const getTrendList = (searchParams: URLSearchParams) => {
  return {
    rank: searchParams.get('rank'),
    isUpDown: searchParams.get('isUpDown'),
    isUpDownStatus: searchParams.get('isUpDownStatus'),
    current:
      Number((Number(searchParams.get('current')) * 100).toFixed(2)) > 0.0
        ? Number((Number(searchParams.get('current')) * 100).toFixed(2))
        : '0.01% ▼',
    before:
      Number((Number(searchParams.get('before')) * 100).toFixed(2)) > 0.0
        ? Number((Number(searchParams.get('before')) * 100).toFixed(2))
        : '0.01% ▼',
    previous:
      Number((Number(searchParams.get('previous')) * 100).toFixed(2)) > 0.0
        ? Number((Number(searchParams.get('previous')) * 100).toFixed(2))
        : '0.01% ▼',
  };
};

export const pluseZeroIndex = (num: string) => {
  return Number(num) < 10 ? '0' + num : num;
};

export const sortSkill = (next: SkillLevelType, prev: SkillLevelType) => {
  return parseInt(prev.statisticsDetails.level) > parseInt(next.statisticsDetails.level)
    ? -1
    : parseInt(prev.statisticsDetails.level) < parseInt(next.statisticsDetails.level)
    ? 1
    : 0;
};

export const sortAttack = (next: AttackOrderType, prev: AttackOrderType) => {
  return parseInt(prev.statisticsDetails.order) > parseInt(next.statisticsDetails.order)
    ? -1
    : parseInt(prev.statisticsDetails.order) < parseInt(next.statisticsDetails.order)
    ? 1
    : 0;
};

/*  tier 구분 값
0    전체
1    상위 등급 구간
2    크리스탈
3    다이아
4    플래티넘
5    골드
6    실버
7    브론즈
*/
// arenaType은 1,2가 있음
// 2에 경우 5성 미만만 참여 가능한 신성 결투장, 1은 모두 참여하는 결투장
export const grapListGenerator = (
  list: WinOrPickRatioType[],
  winOrPick: string,
  arenaType: number
) => {
  const result: {
    tier: string;
    rate: string;
  }[] = [];
  // 전체 구간은 화면에 나타나지 않기에 필터링
  // 지금 결투장만 보여 주기 위해 필터링
  for (let i = 1; i < 8; i++) {
    // 아래나 타입이 같으면서, 구하고자 하는 tier일 시
    const findItem = list.find(
      (item) => item.statisticsDetails.tier === String(i) && item.contents === String(arenaType)
    );

    result.push({
      tier: findItem ? findItem.statisticsDetails.tier : String(i),
      rate: findItem
        ? winOrPick === 'PickRate'
          ? (Number(findItem.statisticsDetails.pickRate) * 100).toFixed(2)
          : (Number(findItem.statisticsDetails.winRate) * 100).toFixed(2)
        : String(0),
    });
  }

  return result;
};

// 비어 있어도 나타내기 위해서 채워주는 함수
export const fillSkillLevelList = (list: SkillLevelType[]) => {
  const result: SkillLevelType[] = [];
  for (let i = 0; i < 16; i++) {
    const findItem = list.find((item) => item.statisticsDetails.level === String(i));
    result.push({
      contents: '1',
      statisticsDetails: {
        level: findItem ? findItem.statisticsDetails.level : String(i),
        rate: findItem ? findItem.statisticsDetails.rate : String(0),
      },
    });
  }

  return result;
};

// 비어 있어도 나타내기 위해서 채워주는 함수
export const fillAttackOrderList = (list: AttackOrderType[]) => {
  const result: AttackOrderType[] = [];
  for (let i = 1; i < 10; i++) {
    const findItem = list.find((item) => item.statisticsDetails.order === String(i));
    result.push({
      contents: '1',
      statisticsDetails: {
        order: findItem ? findItem.statisticsDetails.order : String(i),
        rate: findItem ? findItem.statisticsDetails.rate : String(0),
      },
    });
  }

  return result;
};

// 2차원 배열이 모두 비어 있는 지 여부 확인
export const isEmptyRuneList = (list: RuneType[][]) => {
  let result = false;
  list.forEach((item) => {
    if (item.length > 0) {
      result = true;
    }
  });

  return result;
};

export const makeRuneList = (runeList?: RuneType[]) => {
  const runeResult: RuneType[][] = [[], [], []];
  if (runeList && runeList.length > 0) {
    for (let i = 0; i < 3; i++) {
      runeList.forEach((rune) => {
        if (rune.statisticsDetails.ranking === String(i + 1)) runeResult[i].push(rune);
      });
    }
  }

  return runeResult;
};

// string으로 내려 온 글자 중 색이 있는 곳에 색이 있는 컴포넌트로 수정
export const nameColorText = (text: string) => {
  const s = text.split(/\\n/g);
  const a = [];
  for (let i = 0; i < s.length; i++) {
    if (/\[((\s*?[A-Za-z0-9]*?)*?)\]/.test(s[i])) {
      const replacer = (_: string, p1: string) => {
        if (p1.length === 6) {
          return '<span style="color:#' + p1 + '">';
        }
        return '';
      };

      const o = s[i].replace(/\[((\s*?[A-Za-z0-9]*?)*?)\]/g, replacer);
      a.push(o);
    } else {
      a.push(s[i]);
    }
  }
  const transText = a.join('<br />').replace(/\[((\s*?-*?)*?)\]/g, '</span>');
  return transText;
};

export const convertLanName = (filterKey: string) => {
  if (filterKey === 'serverCode') return 'Server';
  if (filterKey === 'contentCode') return 'Mode';
  if (filterKey === 'bossCode') return 'BossType';
  if (filterKey === 'tierCode') return 'Tier';
  if (filterKey === 'dataCode') return 'Time';
  if (filterKey === 'attackCode') return 'BattleType';
  if (filterKey === 'rankCode') return 'RateTitle';
};

// top10 차트를 만들기 위한 데이터
export const makeChartData = (top10List: Top10Type, characterData: CharacterType[]) => {
  const chartData: ChartType[] = [];
  const legendList: { color: string; label: string }[] = [];
  Object.keys(top10List).forEach((key) => {
    top10List[Number(key)].forEach((i) => {
      const charData = characterData.find((char) => char._uniqueCode === i.charCode);
      const data = {
        x: key,
        y: Math.floor(Number(i.rate) * 100),
      };
      if (chartData.find((ar) => ar.id === getCharName(charData))) {
        chartData.find((ar) => ar.id === getCharName(charData) || '')?.data.push(data);
      } else {
        chartData.push({ id: getCharName(charData) || '', data: [data] });
      }
    });
  });

  Object.keys(top10List).forEach((key) => {
    chartData.forEach((cd: ChartType) => {
      const chartItem = cd.data.find((item: ChartDataType) => item.x === key);
      if (!chartItem) {
        cd.data.push({
          x: key,
          y: null,
        });
      }
    });
  });

  for (let i = 0; i < chartData.length; i++) {
    chartData[i].color = ColorArray[i];
    legendList[i] = { color: ColorArray[i], label: chartData[i].id };
    chartData[i].data = chartData[i].data.sort((a: ChartDataType, b: ChartDataType) =>
      a.x > b.x ? 1 : -1
    );
  }

  return { chartData, legendList };
};
