import type { CharacterType, RankTypeData, RankType } from '@types';
import { useGaHook } from 'hooks/useGaHook';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCharName, getImgUrl, getRankInfo, getRateText } from 'utils';

interface Props {
  index: number;
  charInfo?: CharacterType;
  currentInfo: RankType;
  rankListData: RankTypeData;
  isWoldBoss: number;
  isWinRate: number;
  content: number;
  serverCode: number;
}

export const RankItem: FC<Props> = ({
  index,
  charInfo,
  currentInfo,
  rankListData,
  isWoldBoss,
  isWinRate,
  content,
  serverCode,
}) => {
  const { gaCharacterSelect } = useGaHook();
  const navigation = useNavigate();
  // 월드 보스이거나, isWinRate에 값이 2(승률)라면 주황색 바가 되어야 하기에
  const isOrangeBar = isWoldBoss === 1 || isWinRate === 2;
  const { before, previous, upDown } = getRankInfo(rankListData, charInfo?._uniqueCode || 0);

  return (
    <tr>
      <td className="battleListTable__no">
        <span>{index + 1}</span>
      </td>
      <td className="battleListTable__fluctuation">
        <span data-rank={upDown.status}>{upDown.value}</span>
      </td>
      <td className="battleListTable__name">
        <button
          type="button"
          onClick={() => {
            gaCharacterSelect();
            navigation(
              `/battlebookInfo/${charInfo?._uniqueCode}?current=${currentInfo.rate}&before=${
                before?.rate
              }&previous=${previous?.rate}&rank=${index + 1}&isUpDown=${
                upDown.value
              }&isUpDownStatus=${
                upDown.status
              }&content=${content}&serverCode=${serverCode}&isWinRate=${
                isWinRate === 2 ? 'WinRate' : 'PickRate'
              }`
            );
          }}
        >
          <img src={getImgUrl(charInfo)} alt={getCharName(charInfo)} />
          <span>{getCharName(charInfo)}</span>
        </button>
      </td>
      <td className="battleListTable__pickRate">
        <div className="rate-bar-container">
          <div
            className={`rate-bar-inner ${isOrangeBar ? 'type2' : ''}`}
            style={{ width: Number(currentInfo.rate) * 100 + '%' }}
          ></div>
          <div className="rate-bar-text">{getRateText(currentInfo)}</div>
        </div>
      </td>
    </tr>
  );
};
