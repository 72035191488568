import type { RuneType } from '@types';
import type { FC } from 'react';
import { pluseZeroIndex } from 'utils';
import { runeUrl } from 'utils/constants';

interface Props {
  rune: RuneType;
}
export const Rune: FC<Props> = ({ rune }) => {
  return (
    <td>
      <span
        data-subopt-number1={rune.statisticsDetails.subRuneType1}
        data-subopt-number2={rune.statisticsDetails.subRuneType2}
      >
        <img
          src={`${runeUrl}/rune${pluseZeroIndex(rune.statisticsDetails.rune)}.png`}
          alt={rune.statisticsDetails.runeName}
        />
      </span>
    </td>
  );
};
