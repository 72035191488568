import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const Redirect = () => {
  const navigation = useNavigate();

  useEffect(() => {
    const code = location.search.split('?id=')[1] || 430;
    switch (location.pathname) {
      case '/index.html':
        navigation('/');
        break;
      case '/mercsindex.html':
        navigation('/mercsindex');
        break;
      case '/skillIndex.html':
        navigation('/skillIndex');
        break;
      case '/detail.html':
        navigation(`/detail/${code}`);
        break;
      case '/battlebookInfo.html':
        navigation(`/battlebookInfo/${code}`);
        break;
    }
  }, []);
  return <></>;
};
