import { TipInfo, UpgradeSkillType } from '@types';
import { Dispatch, FC, SetStateAction } from 'react';
import { resourceUrl } from 'utils/constants';

interface Props {
  index: number;
  item: UpgradeSkillType;
  setPopupData: Dispatch<SetStateAction<TipInfo[]>>;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export const MisticUpgradeSkillItem: FC<Props> = ({ item, setPopupData, setVisible, index }) => {
  return (
    <div className="content__skillList--div content__skillList--type1 mistic-upgrade">
      <button
        data-upgrade-index={index}
        type="button"
        onClick={() => {
          setPopupData(item.tooltipArray);
          setVisible(true);
        }}
      >
        <img src={`${resourceUrl}/skill/${item.icon}.png`} alt="" />
      </button>
    </div>
  );
};
