import type { FC } from 'react';
import type { CharacterType } from '@types';
import { getCharaterUrl, getCharName } from 'utils';
import { Link } from 'react-router-dom';

interface Props {
  CharacterInfo: CharacterType;
}

export const SkillHasCharter: FC<Props> = ({ CharacterInfo }) => {
  return (
    <div
      className="col-2"
      data-grade={CharacterInfo._star}
      data-specific={CharacterInfo._type}
      data-grow={CharacterInfo._growType}
    >
      <div className="content__charBlock">
        <Link to={`/detail/${CharacterInfo._uniqueCode}`}>
          <span className="content__charBlock--image">
            <img src={getCharaterUrl(CharacterInfo)} alt={getCharName(CharacterInfo)} />
          </span>
          <p className="content__charBlock--name ">{getCharName(CharacterInfo)}</p>
        </Link>
      </div>
    </div>
  );
};
