import type { CustomSkillType, SkillIconType, TipInfo } from '@types';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { getSkillImg, getTooltipInfo } from 'utils/charaterInfo';
import { RangeSkillIcon } from './RangeSkill';
import { SkillIcon } from './SkillIcon';

interface Props {
  skill: CustomSkillType;
  growType: number;
  rowIndex: number;
  type: number;
  allSkillData: CustomSkillType[];
  setPopupData: (item: TipInfo[]) => void;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export const SkillRow: FC<Props> = ({
  allSkillData,
  skill,
  growType,
  rowIndex,
  type,
  setPopupData,
  setVisible,
}) => {
  const [iconList, setIconList] = useState<SkillIconType[]>([]);

  useEffect(() => {
    const iconInfo: SkillIconType[] = [
      { addClass: '', unLockLevel: '', img: '', tipInfo: [] },
      { addClass: '', unLockLevel: '', img: '', tipInfo: [] },
      { addClass: '', unLockLevel: '', img: '', tipInfo: [] },
      { addClass: '', unLockLevel: '', img: '', tipInfo: [] },
      { addClass: '', unLockLevel: '', img: '', tipInfo: [] },
    ];

    // 4개에 스킬 관련 정보 셋팅
    for (let i = 0; i < 5; i++) {
      const buff = skill.buffList[i];
      const iconImg = getSkillImg(buff, allSkillData, i);
      iconInfo[i].img = iconImg;
      iconInfo[i].tipInfo = getTooltipInfo(buff.buffInfo);
      switch (buff.skillUpgrade) {
        case 0:
          iconInfo[i].unLockLevel = `+ ` + buff.unLockLevel;
          break;
        case 1 || 2:
          iconInfo[i].addClass = 'skillup skillUpType1';
          break;
        case 3:
          iconInfo[i].addClass = 'skillup skillUpType2';
          break;
        case 4:
          iconInfo[i].addClass = 'noOpen noOpenType1';
          iconInfo[i].unLockLevel = `+ ` + buff.unLockLevel;
          break;
      }

      // 각성일 경우
      if (i === 4) {
        if (iconImg) iconInfo[i].addClass = iconInfo[i].addClass + ` noOpen noOpenType2`;
      }
    }

    setIconList(iconInfo);
  }, []);

  return (
    <li>
      <div className="row">
        {iconList.map((i: SkillIconType, itemIndex: number) => {
          if (itemIndex === 0) {
            return (
              <RangeSkillIcon
                key={itemIndex + 'skill'}
                uiActionNum={skill.buffList ? skill.buffList[0].uiActionNum : "0"}
                addClass={i.addClass}
                rowIndex={rowIndex}
                growType={growType}
                type={type}
              />
            );
          }
          return (
            <SkillIcon
              key={itemIndex + 'skill'}
              item={i}
              rowIndex={rowIndex}
              itemIndex={itemIndex}
              setPopupData={setPopupData}
              setVisible={setVisible}
            />
          );
        })}
      </div>
    </li>
  );
};
