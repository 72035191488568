import type { FC } from 'react';

interface Props {
  legend: { color: string; label: string };
}

export const Legend: FC<Props> = ({ legend }) => {
  return (
    <div className="ct-external-label-container" key={legend.label}>
      <div className="ct-external-label-box" style={{ backgroundColor: legend.color }}></div>
      <div className={'ct-external-label'}>{legend.label}</div>
    </div>
  );
};
