import type { CharacterType, SkillItemType } from '@types';
import { useGaHook } from 'hooks/useGaHook';
import type { FC } from 'react';
import { useState } from 'react';
import { getSkillText, getColorObject } from 'utils';
import { resourceUrl } from 'utils/constants';
import { SkillHasCharter } from './SkillHasCharter';

interface Props {
  skillInfo: SkillItemType;
  activeTab: number;
  allCharactersData: CharacterType[];
}

export const SkillItem: FC<Props> = ({ skillInfo, allCharactersData }) => {
  const { gaSkillSelect } = useGaHook();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const obj = getColorObject(getSkillText(skillInfo._buffCodeObject._toolTipObj1));
  const skillImgUrl = `${resourceUrl}/skill/${
    skillInfo._buffCodeObject._buffBigIcon.split('*')[1]
  }.png`;

  return (
    <div className="card" id={`card-${skillInfo._buffCode}`} key={skillInfo._buffCode}>
      <dl>
        <dt className={`row ${isOpen && 'open'}`}>
          <div
            className="col-2 skill-icon"
            onClick={() => {
              setIsOpen(!isOpen);
              gaSkillSelect(obj.text);
            }}
          >
            <img src={skillImgUrl} alt={obj.text} />
          </div>
          <div className="col-10 skill-name">
            <span style={{ color: obj.color }}>{obj.text}</span>
          </div>
        </dt>
        <dd>
          <div className="row">
            {skillInfo._charCodeList.map((item) => {
              const charInfo = allCharactersData.find((i) => i._code === item);
              if (charInfo) return <SkillHasCharter key={item} CharacterInfo={charInfo} />;
            })}
          </div>
        </dd>
      </dl>
    </div>
  );
};
