import type { CharacterDetailType, StatType } from '@types';
import { useState } from 'react';
import { calcCharacterInfo } from 'utils/calcCharacterInfo';

export const useCharacterInfoHook = (totalData: CharacterDetailType) => {
  // 스탯과, 캐릭터 관련 전체 정보(스킬 및 이미지 정보 포함)
  const {
    getBattleCharStat,
    getCharbasicInfo,
    isMistic,
    characterStatList,
    skillList,
    mainSkill,
    misticSkillList,
    getInitData,
    getImgUrl,
    skillRange,
  } = calcCharacterInfo(totalData);

  const [stat, setStat] = useState<StatType>(getInitData());
  const [startLevelIndex, setStarLevelIndex] = useState<number>(0);
  const [reinforce, setReinforce] = useState<number>(0);

  // starLevel 변경 시
  const changeStarLevel = ({ isDown }: { isDown: boolean }) => {
    const changeValue = isDown ? -1 : 1;
    // startLevelIndex를 ++ 하는 데, characterStatList.length 보다 크다면
    if (!isDown && startLevelIndex + 1 >= characterStatList.length) {
      // growType === 0 라면, 강화 할수 없기에 아무것도 하지 않음
      if (stat.growType === 0) return;
    }

    // 업버튼이면서 startLevelIndex가 characterStatList.length - 1 라면
    // 각성 후 강화임
    if (!(isDown && reinforce === 0) && startLevelIndex === characterStatList.length - 1) {
      reinforceChange({ isDown });
      return;
    }

    // 그 외에 경우 별에 따른 변화
    const { currentLevel, star, growType, maxLevel, type } = getCharbasicInfo(
      startLevelIndex + changeValue
    );
    setStat({
      ...stat,
      starImgUrl: getImgUrl(star, growType, isMistic, reinforce),
      battleStatus: getBattleCharStat(currentLevel, startLevelIndex + changeValue),
      maxLevel,
      currentLevel,
      growType,
      star,
      type,
    });
    setStarLevelIndex(startLevelIndex + changeValue);
  };

  // 스타를 모두 올리고 강화 시
  const reinforceChange = ({ isDown }: { isDown: boolean }) => {
    const changeValue = isDown ? -1 : 1;
    const { currentLevel, star, growType, maxLevel, type } = getCharbasicInfo(
      startLevelIndex,
      reinforce + changeValue
    );

    Promise.resolve()
      .then(() => {
        setStat({
          ...stat,
          starImgUrl: getImgUrl(star, growType, isMistic, reinforce + changeValue),
          battleStatus: getBattleCharStat(currentLevel, startLevelIndex),
          maxLevel,
          currentLevel,
          growType,
          star,
          type,
        });
      })
      .then(() => {
        setReinforce(reinforce + changeValue);
      });
  };

  // level 증가 시
  const changeLevel = ({ isDown }: { isDown: boolean }) => {
    const changeValue = isDown ? -1 : 1;
    setStat({
      ...stat,
      currentLevel: stat.currentLevel + changeValue,
      battleStatus: getBattleCharStat(stat.currentLevel + changeValue, startLevelIndex),
    });
  };

  // 레벨을 끝까지 올리거나 내릴 시
  const changeMinMaxLevel = ({ isMin }: { isMin: boolean }) => {
    let changeCurrentLevel = stat.maxLevel;
    // 강화 시에는 MaxLevel - 5 까지만 내릴 수 있기에
    if (isMin) changeCurrentLevel = reinforce > 0 ? stat.maxLevel - 5 : 1;
    setStat({
      ...stat,
      currentLevel: reinforce > 0 && isMin ? stat.maxLevel - 5 : changeCurrentLevel,
      battleStatus: getBattleCharStat(changeCurrentLevel, startLevelIndex),
    });
  };

  // 스타 레벨을 올릴 수 있는 지 여부
  // 신화에 경우 불가능
  const canStarLevelUp = () => {
    if (isMistic) return true;
    if (stat.growType === 0) {
      return characterStatList.length - 1 === startLevelIndex;
    }

    return reinforce === 6;
  };

  // 스타 레벨을 내릴 수 있는 지 여부
  // 신화에 경우 불가능
  const canStarLevelDown = () => {
    if (isMistic) return true;
    return startLevelIndex === 0 && reinforce === 0;
  };

  // 레벨을 내릴 수 있는 지 여부
  const canLevelDown = () => {
    if (reinforce !== 0) {
      return stat.currentLevel === stat.maxLevel - 5;
    }

    return stat.currentLevel === 1;
  };

  return {
    stat,
    changeLevel,
    changeMinMaxLevel,
    changeStarLevel,
    startLevelIndex,
    canStarLevelUp,
    reinforce,
    canStarLevelDown,
    canLevelDown,
    skillList,
    mainSkill,
    misticSkillList,
    isMistic,
    skillRange,
  };
};
