import type { CharBasicType, HelperCharInfo } from '@types';
import { useGaHook } from 'hooks/useGaHook';
import { useI18Hook } from 'hooks/useI18Hook';
import type { FC } from 'react';
import { useState } from 'react';
import { getCharName } from 'utils';

type Props = {
  imageInfo: {
    mercenaries: { illust: string; inGame: string };
    companions: { illust: string; inGame: string };
  };
  characterBasic: CharBasicType | undefined;
  helperInfo: HelperCharInfo | undefined;
};

type ImageFilterCharacterTab = 'mercenaries' | 'companions';
type ImageType = 'illust' | 'inGame';

export const CharaterImage: FC<Props> = ({ imageInfo, characterBasic, helperInfo }) => {
  console.log(characterBasic?._dynamicSkillBool);
  const { gaCharacterImageTypeSelect } = useGaHook();
  const { tt } = useI18Hook();
  // 용병 혹은 조력자 여부
  const [type, setType] = useState<ImageFilterCharacterTab>('mercenaries');
  // 일러스트 혹은 전투이미지
  const [imageType, setImageType] = useState<ImageType>(
    imageInfo.mercenaries.illust !== '' ? 'illust' : 'inGame'
  );

  return (
    <div className="col-w-350">
      <div className="row px-10">
        <div className="button_group row select_mercsType mb-10">
          <div className="col-6 pr-10">
            <button
              type="button"
              className={`button type4 default ${type === 'mercenaries' ? 'active' : ''}`}
              onClick={() => {
                setType('mercenaries');
              }}
            >
              {tt('Mercenaries')}
            </button>
          </div>
          <div className="col-6 pr-10">
            <button
              type="button"
              disabled={imageInfo['companions'].illust === ''}
              className={`button type4 default 
                ${type === 'companions' && 'active'}
                ${imageInfo['companions'].illust === '' && 'none'}
                `}
              onClick={() => {
                setType('companions');
              }}
            >
              {tt('Companions')}
            </button>
          </div>
        </div>
      </div>
      <div
        className="content__thumbnail"
        data-grade={characterBasic ? characterBasic._star : 5}
        data-specific={characterBasic ? characterBasic._type : ''}
        data-grow={characterBasic ? characterBasic._growType : ''}
        data-dynamicSkillBool={characterBasic ? characterBasic._dynamicSkillBool : ''}
      >
        <span className="content__thumbnail--image">
          <img
            src={imageInfo[type][imageType]}
            alt=""
            className={imageType === 'illust' ? '' : 'img-inGame'}
          />
        </span>
        <p className="content__thumbnail--name">
          {getCharName(type === 'mercenaries' ? characterBasic : helperInfo)}
        </p>
      </div>
      <div className="row px-10">
        <div className="button_group row select_imageType">
          <div className="col-6 pr-10">
            <button
              type="button"
              disabled={imageInfo['mercenaries'].illust === ''}
              className={`button type3 
              ${imageType === 'illust' ? 'active' : ''}
              ${imageInfo['mercenaries'].illust === '' && 'none'}
                `}
              onClick={() => {
                gaCharacterImageTypeSelect('illust');
                setImageType('illust');
              }}
            >
              {tt('Illustration')}
            </button>
          </div>
          <div className="col-6 pr-10">
            <button
              type="button"
              className={`button type3 ${imageType === 'inGame' ? 'active' : ''}`}
              onClick={() => {
                gaCharacterImageTypeSelect('inGame');
                setImageType('inGame');
              }}
            >
              {tt('CombatCharacter')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
