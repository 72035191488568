import { TipInfo, UpgradeSkillType } from '@types';
import { useI18Hook } from 'hooks/useI18Hook';
import { Dispatch, FC, SetStateAction } from 'react';
import { getColorObject, getSkillText } from 'utils';
import { MisticUpgradeSkillItem } from './MisticUpgradeSkillItem';

interface Props {
  upgrdaeArray: UpgradeSkillType[];
  setPopupData: Dispatch<SetStateAction<TipInfo[]>>;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export const MisticUpgradeSkillRow: FC<Props> = ({ upgrdaeArray, setPopupData, setVisible }) => {
  const { tt } = useI18Hook();
  return (
    <div className="sub-skill-wrapper">
      {upgrdaeArray.length > 0 ? (
        <>
          <div
            className="sub-skill-name"
            style={{
              color: getColorObject(getSkillText(upgrdaeArray[0].tooltipArray[0])).color,
            }}
          >
            {getColorObject(getSkillText(upgrdaeArray[0].tooltipArray[0])).text}
          </div>
          <div className="sub-skillList-container">
            {upgrdaeArray.map((item, index) => (
              <MisticUpgradeSkillItem
                key={'MisticUpgradeSkillItem' + index}
                item={item}
                index={index}
                setVisible={setVisible}
                setPopupData={setPopupData}
              />
            ))}
          </div>
        </>
      ) : (
        <span className="no-skill-upgrade-text">{tt('GuideMisticSkill')}</span>
      )}
    </div>
  );
};
