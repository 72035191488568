import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import langEn from './lang.en.json';
import langKo from './lang.ko.json';
import langJa from './lang.ja.json';
import langZh from './lang.zh.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Storage } from 'utils/storage';

const resources = {
  en: { translations: langEn },
  ko: { translations: langKo },
  ja: { translations: langJa },
  zh: { translations: langZh },
};

const { getLanguage } = Storage();
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    // 초기 설정 언어
    lng: getLanguage() || 'ko',
    fallbackLng: ['ko', 'en', 'ja', 'zh'],
    debug: false,
    defaultNS: 'translations',
    ns: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: ['ko', 'en', 'ja', 'zh'],
  });

export default i18n;
