import { AttackSkillGraph } from 'components/battlebookInfo/AttackSkillGraph';
import { BattlebookFilter } from 'components/battlebookInfo/BattleBookFilter';
import { BestPosition } from 'components/battlebookInfo/BestPosition';
import { BestRune } from 'components/battlebookInfo/BestRune';
import { CloseCharacter } from 'components/battlebookInfo/CloseCharacter';
import { GradeGrap } from 'components/battlebookInfo/GradeGrap';
import { TrendItem } from 'components/battlebookInfo/TrendItem';
import { Loading } from 'components/layout/Loading';
import { PageTab } from 'components/layout/PageTab';
import { useDomChangeHook } from 'hooks/useDomChangeHook';
import { useI18Hook } from 'hooks/useI18Hook';
import { useStatistic } from 'hooks/useStatistic';
import { useEffect } from 'react';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import { getCharName } from 'utils';
import { getCharStar } from 'utils/charaterInfo';

export const BattleBookInfo: FC = () => {
  const { id } = useParams();
  const { tt } = useI18Hook();
  const {
    closeCharImg,
    runeList,
    positionList,
    skillLevelList,
    attackOrderList,
    pickOrWinRatioList,
    loading,
    characterData,
    server,
    winOrPick,
    setServer,
    setWinOrPick,
    contentType,
    setContentType,
    characterInfo,
  } = useStatistic(id || '');
  useDomChangeHook(
    `${getCharName(characterInfo) || ''} ${tt('DetailTitle')} - Master Your Tactics, BROWNDUST`
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section id="container" className="detailpage">
        <PageTab currentTab="BattleBookEncyclopedia" />
        {loading && <Loading />}
        <div className="content" id="battlebookInfo">
          <div className="container_section usrData_section">
            <BattlebookFilter
              charName={getCharName(characterInfo) || ''}
              star={getCharStar(characterData)}
              server={server}
              winOrPick={winOrPick}
              setServer={setServer}
              setWinOrPick={setWinOrPick}
              contentType={contentType}
              setContentType={setContentType}
            />
            <TrendItem characterData={characterData} />

            <div className="row usrData_section--cnt">
              <div className="col">
                <div className="userDataNotice ">{tt('UserDataNotice')}</div>
              </div>
            </div>

            <div className="row usrData_section--cnt">
              <CloseCharacter closeCharImg={closeCharImg} />
              <BestPosition positionList={positionList} />
            </div>

            <div className="row usrData_section--cnt">
              <AttackSkillGraph skillLevelList={skillLevelList} attackOrderList={attackOrderList} />
            </div>

            <div className="row usrData_section--cnt">
              <BestRune runeList={runeList} />
              <GradeGrap
                winOrPick={winOrPick}
                pickOrWinRatioList={pickOrWinRatioList}
                contentType={contentType}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
