import type { RuneType } from '@types';
import { GatheringData } from 'components/layout/GatheringData';
import { useI18Hook } from 'hooks/useI18Hook';
import type { FC } from 'react';
import { makeRuneList, isEmptyRuneList } from 'utils';
import { Rune } from './Rune';

interface Props {
  runeList?: RuneType[];
}

export const BestRune: FC<Props> = ({ runeList }) => {
  const { tt } = useI18Hook();

  return (
    <div className="col-w-250">
      <div className="content__info mln">
        <h3 className="substring ">{tt('UserDataTitle3')}</h3>
        <div className="box" id="box_rune">
          {isEmptyRuneList(makeRuneList(runeList)) ? (
            <table>
              <tbody>
                {makeRuneList(runeList).map((runeList, index1) => {
                  return (
                    <tr key={'runeTr' + index1}>
                      {runeList.length > 0 && <th>{index1 + 1}</th>}
                      {runeList.map((rune, index2) => (
                        <Rune key={'runeTd' + index2} rune={rune} />
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <GatheringData />
          )}
        </div>
      </div>
    </div>
  );
};
