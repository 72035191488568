import type { StatType } from '@types';
import { useI18Hook } from 'hooks/useI18Hook';
import type { FC } from 'react';

interface Props {
  stat: StatType;
}

export const StatList: FC<Props> = ({ stat }) => {
  const { tt } = useI18Hook();
  return (
    <div className="content__info--statList">
      <ul>
        <li className={`damage ${stat.type === 4 && 'damage2'}`}>
          <dl>
            <dt className="">{stat.type === 4 ? tt('Support') : tt('ATK')}</dt>
            <dd>
              {stat && stat.type === 4
                ? stat.battleStatus.supportValue + '%'
                : stat.battleStatus.damageValue}
            </dd>
          </dl>
        </li>
        <li className="health">
          <dl>
            <dt className="">{tt('HP')}</dt>
            <dd>{stat && stat.battleStatus.maxHealth}</dd>
          </dl>
        </li>
        <li className="reduce">
          <dl>
            <dt className="">{tt('DEF')}</dt>
            <dd>{stat && stat.battleStatus.damageReduce}%</dd>
          </dl>
        </li>
        <li className="critical">
          <dl>
            <dt className="">{tt('CritRate')}</dt>
            <dd>{stat && stat.battleStatus.criticalRate}%</dd>
          </dl>
        </li>
        <li className="effect">
          <dl>
            <dt className="">{tt('CritDamage')}</dt>
            <dd>{stat && stat.battleStatus.criticalEffectRate}%</dd>
          </dl>
        </li>
        <li className="dodge">
          <dl>
            <dt className="">{tt('AGI')}</dt>
            <dd>{stat && stat.battleStatus.dodgeRate}%</dd>
          </dl>
        </li>
      </ul>
    </div>
  );
};
